// Angular libraries
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Global
import * as Settings from '../../../global/settings';
import { ServerRoutes } from '../../.././global/routes';

// Shared
import { StatusCodes } from '../../../../../../shared/status.codes';

// Models
import * as GlobalModels from '../../../models/global.models';

// Systems
import { Notifications } from '../../../systems/notifications';
import { Global } from '../../../systems/global';

// Notifications
const deleteGameNotifications = {
  [StatusCodes.INTERNAL]: "‫אופס! נתקלנו בשגיאה. אנא נסה שוב בהמשך.‬‎",
  [StatusCodes.INVALID_TOKEN]: "הזדהה בכדי לבצע פעולה זו.",
  [StatusCodes.OBJECT_NOT_FOUND]: "משחק לא נמצא.",
  [StatusCodes.COULDNT_DELETE_GAME]: "משויכות קבוצות עדיין למשחק זה.",
  [StatusCodes.OK]: "המשחק נמחק בהצלחה!"
};

@Component({
  selector: 'app-delete-game',
  templateUrl: './delete-game.component.html',
  styleUrls: ['../../dialogs.style.scss']
})
export class DeleteGameComponent implements OnInit {

  private notifications: Notifications;
  private global: Global;

  gameDeleted: GlobalModels.Game;

  loading = false;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DeleteGameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GlobalModels.Game) { 
      this.gameDeleted = {
        id: data.id,
        name: data.name,
        iconSrc: data.iconSrc
      };
      this.global = new Global(this.http, true);
      this.notifications = new Notifications(snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);
   }

  ngOnInit(): void {
  }


  onDeleteSubmitted(): void {

    this.loading = true;

    this.global.DeleteGame(this.gameDeleted.id).subscribe(
      (data) => {
        this.notifications.Ok(deleteGameNotifications[StatusCodes.OK]);
        this.dialogRef.close(true);
      },
      (err) => {
        this.notifications.Error(err.error.error_code === undefined ? 
          deleteGameNotifications[StatusCodes.INTERNAL] : deleteGameNotifications[err.error.error_code]);
      }
    );
  }
  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
