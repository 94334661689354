<!-- Stats -->
<div class="row">
  <div class="col-md-12">
    <h2 class="ls-top-header">ניהול משתמשים</h2>
  </div>
</div>

<!-- Active Users. -->
<div class="panel-dark" style="border: unset !important;">
    <div class="panel-heading">
        <span class="panel-title">משתמשים פעילים</span>
    </div>
    <div class="panel-body" dir="rtl">
      <!-- Payments History Table. -->
      <mat-form-field style="text-align: right;width: 40%;">
        <mat-label>חיפוש</mat-label>
        <input matInput (keyup)="filterUsers($event)" placeholder="הזן שם משתמש / מספר פלאפון" #input>
      </mat-form-field>
      <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
        <table class="table-striped table-responsive-md" mat-table [dataSource]="activeUsersData"
          matSortActive="started" matSortDisableClear matSortDirection="desc" #activeUsersSort="matSort" matSort>
          <!-- FullName -->
          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> משתמש </th>
            <td mat-cell *matCellDef="let user" class="td-details">
              #{{ Formats.zeroPad(user.id, 4) }} - {{ user.fullName }}

              <span class="badge {{ Formats.PrivilegeText[user.privilege].class }}" style="margin-right: 5px;">
                {{ Formats.PrivilegeText[user.privilege].text }}
              </span>

              <span class="badge badge-declined" *ngIf="user.status === UserStatusTypes.SUSPENDED" style="margin-right: 4px;">
                מורחק
              </span>

              <span class="badge badge-recommend" *ngIf="user.recommend" style="margin-right: 4px;">
                ממליץ
              </span>
              
            </td>
          </ng-container>
          <!-- Mobile -->
          <ng-container matColumnDef="mobile">
            <th class="table-header" mat-header-cell *matHeaderCellDef> פלאפון </th>
            <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let user">
              {{ Formats.ViewPhone(user.phone) }}
            </td>
          </ng-container>
          <!-- Created -->
          <ng-container matColumnDef="created">
            <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך הרשמה </th>
            <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let user">
              {{ this.datePipe.transform(user.created, 'HH:mm - dd/MM/yyyy') }}
            </td>
          </ng-container>
          <!-- Options -->
          <ng-container matColumnDef="options">
            <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
            <td mat-cell *matCellDef="let user">
              <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
              <mat-menu #menu="matMenu">
                <button class="reco-option-btn" (click)="onEditUserDialog(user)" mat-menu-item>
                  <i class="fa fa-edit" style="font-size: 15px;"></i>&nbsp;&nbsp;עריכה 
                </button>
                <button class="reco-option-btn" mat-menu-item>
                  <i class="fa fa-file-text-o" style="font-size: 15px;"></i>&nbsp;&nbsp;תשלומים</button>
                <button class="reco-option-btn" *ngIf="user.status !== UserStatusTypes.SUSPENDED" (click)="onBanUserDialog(user)" style="color:#901d28" mat-menu-item>
                  <i class="fa fa-ban" style="font-size: 15px;"></i>&nbsp;&nbsp;הרחקה
                </button>
                <button class="reco-option-btn" *ngIf="user.status === UserStatusTypes.SUSPENDED" (click)="onUnBanUserDialog(user)" mat-menu-item>
                  <i class="fa fa-times" style="font-size: 15px;"></i>&nbsp;&nbsp;בטל הרחקה
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="activeUsersColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: activeUsersColumns;"></tr>
        </table>
        <!-- Search not found. -->
        <div style="margin-right: 10px" *ngIf="activeUsersBadSearch !== ''">
          <h4>אין נתונים התואמים את החיפוש "{{ activeUsersBadSearch }}"</h4>
        </div>
        <!-- Not found items situation. -->
        <div style="margin-right: 10px" *ngIf="totalActiveUsers <= 0">
          <h4>אין משתמשים לתצוגה.</h4>
        </div>
        <!-- Paginator setup. -->
        <mat-paginator 
          #activeUsersPaginator
          [length]="totalActiveUsers"
          [pageSize]="Settings.DEFAULT_PAGE_SIZE"
          [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
          [showFirstLastButtons]="true"></mat-paginator>
      </div>
    </div>
</div>