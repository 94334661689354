import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import * as Settings from '../global/settings';
import * as RecoModels from '../models/reco.models';
import * as UserModels from '../models/user.models';
import * as GlobalModels from '../models/global.models';
import * as PaymentModels from '../models/payment.models';

export const RecoStautsText = {
  [RecoModels.StatusTypes.NONE]: { 
    text: "ללא",
    class: "badge-default"
  },
  [RecoModels.StatusTypes.OPEN]: { 
    text: "פתוחה",
    class: "status-open"
  },
  [RecoModels.StatusTypes.ENDED]: { 
    text: "הסתיימה",
    class: "status-ended"
  },
  [RecoModels.StatusTypes.PENDING]: { 
    text: "בהמתנה",
    class: "status-pending"
  },
  [RecoModels.StatusTypes.CANCELED]: { 
    text: "בוטלה",
    class: "status-postponed"
  }
};

export const PrivilegeText = {
  [UserModels.PrivilegeTypes.NONE]: {
    text: "רשום",
    class: "status-pending"
  },
  [UserModels.PrivilegeTypes.STAFF]: { 
    text: "צוות Winvice",
    class: "badge-default"
  },
  [UserModels.PrivilegeTypes.ADMIN]: { 
    text: "אדמין",
    class: "status-open"
  }
};

export const UserStautsText = {
  [UserModels.StatusTypes.SUSPENDED]: {
    text: "מורחק",
    class: "status-pending"
  },
};

export const PaymentStatusText = {
  [PaymentModels.StatusTypes.NONE]: {
    text: "ללא",
    class: "status-none"
  },
  [PaymentModels.StatusTypes.PENDING]: {
    text: "בהמתנה",
    class: "status-ended"
  },
  [PaymentModels.StatusTypes.PAIED]: {
    text: "שולם",
    class: "status-open"
  },
  [PaymentModels.StatusTypes.FAILED]: {
    text: "סירוב",
    class: "status-closed"
  },
  [PaymentModels.StatusTypes.CANCELED]: {
    text: "בוטלה",
    class: "status-closed"
  },
  [PaymentModels.StatusTypes.WRONG]: {
    text: "טועה",
    class: "status-closed"
  },
}

export const DrawStatusText = {
  [PaymentModels.DrawStatusTyepes.NONE]: {
    text: "ללא",
    class: "status-none"
  },
  [PaymentModels.DrawStatusTyepes.PENDING]: {
    text: "ממתין לאישור",
    class: "status-ended"
  },
  [PaymentModels.DrawStatusTyepes.ATTRACTED]: {
    text: "אושרה",
    class: "status-open"
  },
}

export const AdvDisplayTypes = {
  [GlobalModels.AdvDisplayTypes.NONE]: "ללא",
  [GlobalModels.AdvDisplayTypes.ALONG]: "לאורך",
  [GlobalModels.AdvDisplayTypes.ACROSS]: "לרוחב"
};

export const zeroPad = (num, places) => String(num).padStart(places, '0')

export function ViewPhone(phone: string): string {
  if(phone.length === Settings.PHONE_NUMBER_LENGTH)
    return [phone.slice(0, 3), Settings.VIEW_PHONE_SIGN, phone.slice(3)].join('');
  return "";
}

export function translatePaginator(paginator: MatPaginator) {
  paginator._intl.previousPageLabel = Settings.PREV_PAGE_LABEL;
  paginator._intl.firstPageLabel = Settings.FIRST_PAGE_LABEL;
  paginator._intl.lastPageLabel = Settings.LAST_PAGE_LABEL;
  paginator._intl.nextPageLabel = Settings.NEXT_PAGE_LABEL;
  paginator._intl.itemsPerPageLabel = Settings.ITEMS_PER_PAGE_LABEL;
}

export function formatDate(date: Date) {
  var event = new Date(date);

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return event.toLocaleDateString('he-IL', options);
}

export function formatDiff(date: Date) {
  moment.locale('he');
  return moment(date).fromNow();
}

export function formatScore(rating: number) {
  return "score s" + ((rating * 2) | 0); 
}

export function progressBarClass(progress: number) {
  if(progress >= 0 && progress < 50)
    return "low-progress-bar";
  else if(progress >= 50 && progress < 80)
    return "medium-progress-bar";
  else if(progress >= 80 && progress <= 100)
    return "high-progress-bar";
}
