<!-- Stats -->
<div class="row">
  <div class="col-md-12">
    <h2 class="ls-top-header">צ'אט תמיכה כללי</h2>
  </div>
</div>

<!-- Active Recommenders -->
<div class="panel-dark" style="border: unset !important;">
  <div class="panel-heading">
      <span class="panel-title">ממליצים פעילים</span>
  </div>
  <div class="panel-body">
    <div id="add_game_notification"></div>
    <div class="col-md-12 col-sm-6">
            <div class="panel-body" dir="rtl">
                <!-- Main Content Element  Start-->
                <div class="row">
                    <div class="col-md-12">
                        <div class="mail-area">
                            <div class="mail-box-list">
                                <div class="mail-search-box">
                                    <form id="searchform2" name="searchform2" method="get" action="index.html">
                                        <div class="fieldcontainer">
                                            <!-- search-user -->
                                            <input type="text" name="search-user" id="search-user" class="searchfieldjs" placeholder="חפש משתמש רשום..." tabindex="2">
                                            <input type="submit" name="search2btn" id="search2btn" value="">
                                        </div>
                                    </form>
                                </div>
                                <ul class="mail-list" #contacts (scroll)="onScrollContactsEvent($event)">
                                  <li [ngClass]="['new-mail', openChatWith === user.userID ? 'chat-active' : '']" *ngFor="let user of chatUsers" (click)="updateChatWith(user.userID)"> 
                                    <div class="mail-user-image"> 
                                      <i class="fa fa-user"></i> 
                                    </div> 
                                    <div>
                                      <span style="margin-right: 8px;">{{ user.fullName }} ({{ Formats.ViewPhone(user.phone) }})</span>
                                    </div>
                                    <div *ngIf="user.unReadMsgs > 0">
                                      <span *ngIf="user.unReadMsgs === 1" style="margin-right: 8px;font-weight: bold;font-size: 13px;">
                                        הודעה חדשה
                                      </span>
                                      <span *ngIf="user.unReadMsgs > 1" style="margin-right: 8px;font-weight: bold;font-size: 13px;">
                                        {{ user.unReadMsgs }} הודעות חדשות
                                      </span>
                                    </div>
                                    <div class="clearfix"></div>
                                  </li>
                                </ul>
                            </div>                                      
                            <div class="mail-body mail-body-inbox">
                                <div id="chat-with" style="font-family: 'Assistant', sans-serif !important;font-size: 18px;">
                                  התכתבות עם <span style="font-weight: bold;">{{ currentConv.chatWith }}</span>
                                </div>
                                <div class="mail-main-content">
                                    <div class="nano nano-chat-widget">
                                        <div class="nano-content nano-chat-box" #chatlogs (scroll)="onScrollChatsEvent($event)" *ngIf="currentConv !== undefined">
                                          <div style="text-align: center;">
                                            <div style="text-align: center;margin-bottom: 10px;margin-top:10px;padding-bottom:10px;" *ngIf="isLoadingChats">
                                              <span class="spinner-border spinner-border-sm mr-1"></span>&nbsp;
                                              טוען הודעות קודמות...
                                            </div>
                                          </div>
                                          <div class="ls-chat" *ngFor="let chat of currentConv.chats">
                                            <div [ngClass]="[chat.sender ? 'triangle-right' : 'triangle']"></div>
                                            <div class="text text-right">
                                            <p [ngClass]="['msg', chat.sender ? 'msg-alter' : '']">
                                              &nbsp;&nbsp;&nbsp;
                                              {{ Formats.formatDiff(chat.created) }}
                                              <span class="date">&nbsp;&nbsp;&nbsp;
                                                {{ chat.message }}
                                              </span>
                                            </p>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div class="ls_form">
                                            <div class="input-group input-group-message">
                                                <input matInput [formControl]="chatMsg" placeholder="כתוב הודעה..." class="form-control">
                                                <span class="input-group-btn" id="chat-send-btn">
                                                    <button type="button" (click)="sendChatMessage()" class="btn ls-light-green-btn">
                                                        שלח הודעה
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <ul class="attachments-preview-list">
                                                <li>
                                                    <div class="ls-mail-img">
                                                        <img src="assets/images/demo/dummy-120x100.jpg" alt="">
                                                        <div class="ls-mail-overlay">
                                                            <div class="ls-mail-overlay-link">
                                                                <a href="#" class=""><i class="fa fa-search"></i></a>
                                                                <a href="#" class=""><i class="fa fa-download"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="ls-mail-img">
                                                        <img src="assets/images/demo/dummy-120x100.jpg" alt="">
                                                        <div class="ls-mail-overlay">
                                                            <div class="ls-mail-overlay-link">
                                                                <a href="#" class=""><i class="fa fa-search"></i></a>
                                                                <a href="#" class=""><i class="fa fa-download"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="ls-mail-img">
                                                        <img src="assets/images/demo/dummy-120x100.jpg" alt="">
                                                        <div class="ls-mail-overlay">
                                                            <div class="ls-mail-overlay-link">
                                                                <a href="#" class=""><i class="fa fa-search"></i></a>
                                                                <a href="#" class=""><i class="fa fa-download"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="ls-mail-img overlay-hover">
                                                        <img src="assets/images/demo/dummy-120x100.jpg" alt="">
                                                        <div class="ls-mail-overlay">
                                                            <div class="ls-mail-overlay-link">
                                                                <a href="#" class=""><i class="fa fa-search"></i></a>
                                                                <a href="#" class=""><i class="fa fa-download"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>