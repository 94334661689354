export enum StatusCodes {
  OK = 200,
  OBJECT_NOT_FOUND = 404,
  FIELDS_REQURIED = 202,
  INTERNAL = 500,

  INVALID_PHONE_NUMBER = 1,
  LONG_FIRST_OR_LAST = 2,
  SHORT_PASSWORD = 3,
  LONG_PASSWORD = 4,
  USER_ALREADY_EXISTS = 5,
  USER_NOT_EXIST = 6,
  INVALID_VERIFY_CODE = 7,
  INVALID_TOKEN = 8,
  WORNG_LOGIN_DETAILS = 9,
  USER_REQUIRE_APPROVAL = 10,
  LOGIN_USER_SUSPEND = 11,
  RECOVER_NEXT_TIME = 12,
  INVALID_EMAIL = 13,
  INVALID_GAME = 14,
  INVALID_GROUPS = 15,
  INVALID_DATE_TIME = 16,
  LOW_DATE_TIME = 17,
  ADD_RECO_FAILED = 18,
  RECORD_EXT_INVALID = 19,
  USER_PROFILE_NOT_FOUND = 20,
  ALREADY_REATED = 21,
  INVALID_RATING_SCORE = 22,
  INVLAID_RATING_OPINION = 23,
  SELF_RATE_NOT_AVILABLE = 24,
  INVALID_CARD_NUM = 25,
  INVALID_CARD_VALIDITY = 26,
  INVALID_CARD_DETAILS = 27,
  INVALID_CARD_TYPE = 28,
  STORE_CARD_FAILED = 29,
  PAYMENT_DETAILS_REQUIRED = 30,
  PLAN_ALREADY_ACTIVE = 31,
  PURCHASE_FAILED = 32,
  ALREADY_SUBSCRIBED = 33,
  INVALID_PASSWORD = 34,
  WRONG_CURRENT_PASSWORD = 35,
  EXTENSION_NOT_ALLOWED = 36,
  DRAW_NOT_AVAILABLE = 37,
  DRAW_ALREADY_REQUESTED = 38,
  CANT_PURCHASE_SELF_RECO = 39,
  RECO_ALREADY_PURCHASED = 40,
  RECO_PURCHASE_FAILED = 41,
  CHAT_NOT_AVAILABLE = 42,
  INVALID_CHAT_MESSAGE = 43,

// Admin
  INVALID_TYPE_NAME = 44,
  TYPE_ALREADY_EXISTS = 45,
  COULDNT_DELETE_GAME = 46,
  GAME_NOT_EXISTS = 47,
  CANT_BAN_YOURSELF = 48,
  USER_ALREADY_BANNED = 49,
  USER_NOT_BANNED = 50,
  WRONG_ADV_TITLE = 51,
  WRONG_ADV_LINK = 52,
  INVALID_ADV_DISPLAY_TYPE = 53,
  INVALID_ADV_IMAGE = 54,
  RECO_SAME_GROUPS = 55,
  USER_NOT_SUBSCRIBE = 56
};
