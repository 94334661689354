import { ThemePalette } from '@angular/material/core';
import { HttpHeaders } from '@angular/common/http';


import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

export const IS_DEVELOPMENT = false;

export const APP_NAME = "Winvice";

export const MAIN_SERVER_URL = IS_DEVELOPMENT ? "http://localhost:4254" : "https://api.winvice.co.il";
export const SERVER_URL = `${MAIN_SERVER_URL}/api`;
export const WEBISTE_URL = IS_DEVELOPMENT ? "http://localhost:4200" : "https://dev.winvice.co.il";

export const PHONE_NUMBER_LENGTH = 10;

export const VIEW_PHONE_SIGN = "-";

export const ROUTING_TIMER_INTERVAL = 4000;

export const LC_CURRENT_USER_KEY = "_currentUser";

export const MAX_GAME_GROUPS = 2;

export const INVALID_GROUP_ID = 0;

export const SELF_USER_IDENTIFY = 0;

export const DEFAULT_LOADED_VALUE = -1;


export class SecuritySettings {
  public static MIN_PASSWORD_LENGTH = 6;
  public static MAX_PASSWORD_LENGTH = 32;
}

export const DATE_TIME_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export const HTTP_CONTENT_TYPE_JSON: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

export const DEFAULT_DIALOG_WIDTH = "470px";

// Paginator
export const DEFAULT_PAGE_SIZE = 5;
export const PAGE_SIZE_OPTIONS: number[] = [5, 10, 20];
export const PREV_PAGE_LABEL = "הקודם";
export const NEXT_PAGE_LABEL = "הבא";
export const FIRST_PAGE_LABEL = "עמוד ראשון";
export const LAST_PAGE_LABEL = "עמוד אחרון";
export const ITEMS_PER_PAGE_LABEL = "פריטים לעמוד";

// DateTime Picker
export class DTPicker
{
  public static showSpinners = true;
  public static showSeconds = false;
  public static touchUi = false;
  public static enableMeridian = false;
  public static stepHour = 1;
  public static stepMinute = 1;
  public static stepSecond = 1;
  public static color: ThemePalette = 'primary';
  public static hideTime = false;
}

// Notifications
export const NOTIF_HORZ_POS: MatSnackBarHorizontalPosition = "right";

export const NOTIF_VERT_POS: MatSnackBarVerticalPosition = "bottom";

export const NOTIF_RESPONSIVE_HORZ_POS: MatSnackBarHorizontalPosition = "center";

export const NOTIF_RESPONSIVE_VERT_POS: MatSnackBarVerticalPosition = "bottom";

export const NOTIF_CLOSE_TEXT = "סגירה";

export const NOTIF_DURATION = 3000;

// Security
export const MIN_PASSWORD_LENGTH = 6;

export const MAX_PASSWORD_LENGTH = 32;

export const ALLOWED_IMAGE_EXTENSIONS: string[] = [ "png", "jpg", "jpeg" ];

export const MAX_DRAW_AMOUNT = 500;
