import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router, ActivatedRoute, CanActivate, CanDeactivate, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as Settings from '../global/settings';
import { ServerRoutes, AppRoutes } from '../global/routes';
import { Users } from '../systems/users';

@Injectable()
export class AuthGuardLoggedIn implements CanActivate {
  users: Users;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private http: HttpClient) {
      this.users = new Users(this.http);
  }

  canActivate(): Observable<boolean> {
    
    return new Observable<boolean>(obs => {
      var userAuth = this.users.Authenticate();
      if(userAuth !== undefined) {
        userAuth.subscribe(
          (data) => {
            obs.next(true);
          },
          (err) => {
            localStorage.removeItem(Settings.LC_CURRENT_USER_KEY);
            this.router.navigate(['/'], { relativeTo: this.route });
            obs.next(false);
          }
        );
      }
      else {
        obs.next(false);
      }
    });
  }
}

@Injectable()
export class AuthGuardNotLoggedIn implements CanActivate {
  users: Users;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private http: HttpClient) {
      this.users = new Users(this.http);
  }


  canActivate(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      var userAuth = this.users.Authenticate();
      if(userAuth !== undefined) {
        userAuth.subscribe(
          (data) => {
            obs.next(false);
          },
          (err) => {
            localStorage.removeItem(Settings.LC_CURRENT_USER_KEY);
            this.router.navigate(['/'], { relativeTo: this.route });
            obs.next(true);
          }
        );
      }
      else {
        obs.next(true);
      }
    });
  }
}