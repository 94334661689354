
<div class="row">
  <div class="col-md-12">
    <h2 class="ls-top-header">ניהול המלצות</h2>
  </div>
</div>

<!-- Open Recommendations. -->
<div class="row home-row-top">
  <div class="col-md-12">
      <div class="panel-dark" style="border: unset !important;">
          <div class="panel-heading">
              <span class="panel-title">המלצות פתוחות</span>
          </div>
          <div class="panel-body">
            <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
              <table class="table-striped table-responsive-md" mat-table [dataSource]="openRecosData"
                matSortActive="started" matSortDisableClear matSortDirection="desc" #openRecosSort="matSort" matSort>
                <!-- Title -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> המלצה </th>
                  <td mat-cell *matCellDef="let reco" class="td-details">
                    <div style="padding-right: 15px;white-space: nowrap;">
                      
                      <p style="margin-top: 3px;margin: 0px 0px 0px !important;padding-bottom: 10px;">
                        <img [src]="reco.game.iconSrc" style="height: 24px; width: 24px;margin-left:4px;"/>  
                        <a href="{{ Settings.WEBISTE_URL }}/reco/{{ reco.id }}" target="_blank">{{ reco.title }}</a>
                      </p>
                      <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="reco.ratio1">
                        <img [src]="reco.group1.imageSrc" class="reco-group-img"/>
                        {{ reco.group1.name }} 
                      </span>
                      &nbsp;<b style="font-size: 15px;">.Vs</b>&nbsp;
                      <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="reco.ratio2">
                        <img [src]="reco.group2.imageSrc" class="reco-group-img"/>
                        {{ reco.group2.name }} 
                      </span>
                    </div>
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="creator">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> ממליץ </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ reco.creator.fullName }}
                  </td>
                </ng-container>
                <!-- Recommended Result -->
                <ng-container matColumnDef="result">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תוצאה מומלצת </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    <span *ngIf="reco.result === RecoResultTypes.FIRST_WIN">
                      ניצחון - {{ reco.group1.name }}
                    </span>
                    <span *ngIf="reco.result === RecoResultTypes.TIE">
                      תיקו
                    </span>
                    <span *ngIf="reco.result === RecoResultTypes.SECOND_WIN">
                      ניצחון - {{ reco.group2.name }}
                    </span>
                  </td>
                </ng-container>
                <!-- Time -->
                <ng-container matColumnDef="datetime">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך ושעה </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ this.datePipe.transform(reco.gameTime, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Created -->
                <ng-container matColumnDef="created">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> פורסמה בתאריך </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ this.datePipe.transform(reco.created, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Options -->
                <ng-container matColumnDef="options">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
                  <td mat-cell *matCellDef="let reco">
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                    <mat-menu #menu="matMenu">
                      <button class="reco-option-btn" (click)="navigateToEditReco(reco.id)" mat-menu-item>
                        עריכה&nbsp;&nbsp;<i class="fa fa-edit" style="font-size: 16px;"></i>
                      </button>
                      <button class="reco-option-btn" (click)="onMarkCorrectDialogShow(reco)" mat-menu-item>
                        אישור תוצאה&nbsp;&nbsp;
                        <i class="fa fa-check" style="font-size: 16px;color:seagreen"></i>
                      </button>
                      <button class="reco-option-btn" (click)="onMarkIncorrectDialogShow(reco)" mat-menu-item>
                        סימון טועה&nbsp;&nbsp;
                        <i class="fa fa-times" style="font-size: 16px;color:#901d28"></i>
                      </button>
                      <button class="reco-option-btn" (click)="onCancelRecoDialogShow(reco)" mat-menu-item>
                        ביטול&nbsp; 
                        <i class="fa fa-ban" style="font-size: 16px;"></i>
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="recoColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: recoColumns;"></tr>
              </table>
              <!-- Not found items situation. -->
              <div style="margin-right: 10px" *ngIf="openRecosTotalItems <= 0">
                <h4>אין כרגע המלצות פתוחות.</h4>
              </div>
              <!-- Paginator setup. -->
              <mat-paginator 
                #openRecosPaginator
                [length]="openRecosTotalItems"
                [pageSize]="Settings.DEFAULT_PAGE_SIZE"
                [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
                [showFirstLastButtons]="true">
              </mat-paginator>
            </div>
          </div>
      </div>
  </div>
</div>

<!-- Pending Recommendations. -->
<div class="row home-row-top">
  <div class="col-md-12">
      <div class="panel-dark" style="border: unset !important;">
          <div class="panel-heading">
              <span class="panel-title">המלצות בהמתנה</span>
          </div>
          <div class="panel-body">
            <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
              <table class="table-striped table-responsive-md" mat-table [dataSource]="pendingRecosData"
                matSortActive="started" matSortDisableClear matSortDirection="desc" #pendingRecosSort="matSort" matSort>
                <!-- Title -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> המלצה </th>
                  <td mat-cell *matCellDef="let reco" class="td-details">
                    <div style="padding-right: 15px;white-space: nowrap;">
                      
                      <p style="margin-top: 3px;margin: 0px 0px 0px !important;padding-bottom: 10px;">
                        <img [src]="reco.game.iconSrc" style="height: 24px; width: 24px;"/>  {{ reco.title }}
                        (<span class="pending-text" style="font-size: 15px;">בהמתנה</span>)
                      </p>
                      <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="reco.ratio1">
                        <img [src]="reco.group1.imageSrc" class="reco-group-img"/>
                        {{ reco.group1.name }} 
                      </span>
                      &nbsp;<b style="font-size: 15px;">.Vs</b>&nbsp;
                      <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="reco.ratio2">
                        <img [src]="reco.group2.imageSrc" class="reco-group-img"/>
                        {{ reco.group2.name }} 
                      </span>
                    </div>
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="creator">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> ממליץ </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ reco.creator.fullName }}
                  </td>
                </ng-container>
                <!-- Recommended Result -->
                <ng-container matColumnDef="result">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תוצאה מומלצת </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    <span *ngIf="reco.result === RecoResultTypes.FIRST_WIN">
                      ניצחון - {{ reco.group1.name }}
                    </span>
                    <span *ngIf="reco.result === RecoResultTypes.TIE">
                      תיקו
                    </span>
                    <span *ngIf="reco.result === RecoResultTypes.SECOND_WIN">
                      ניצחון - {{ reco.group2.name }}
                    </span>
                  </td>
                </ng-container>
                <!-- Time -->
                <ng-container matColumnDef="datetime">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך ושעה </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ this.datePipe.transform(reco.gameTime, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Created -->
                <ng-container matColumnDef="created">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> פורסמה בתאריך </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ this.datePipe.transform(reco.created, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Options -->
                <ng-container matColumnDef="options">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
                  <td mat-cell *matCellDef="let reco">
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                    <mat-menu #menu="matMenu">
                      <button class="reco-option-btn" (click)="onMarkCorrectDialogShow(reco)" mat-menu-item>
                        אישור תוצאה&nbsp;&nbsp;
                        <i class="fa fa-check" style="font-size: 16px;color:seagreen"></i>
                      </button>
                      <button class="reco-option-btn" (click)="onMarkIncorrectDialogShow(reco)" mat-menu-item>
                        סימון טועה&nbsp;&nbsp;
                        <i class="fa fa-times" style="font-size: 16px;color:#901d28"></i>
                      </button>
                      <button class="reco-option-btn" (click)="onCancelRecoDialogShow(reco)" mat-menu-item>
                        ביטול&nbsp; 
                        <i class="fa fa-ban" style="font-size: 16px;"></i>
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="recoColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: recoColumns;"></tr>
              </table>
              <!-- Not found items situation. -->
              <div style="margin-right: 10px" *ngIf="pendingRecosTotal <= 0">
                <h4>אין כרגע המלצות בהמתנה.</h4>
              </div>
              <!-- Paginator setup. -->
              <mat-paginator 
                #pendingRecosPaginator
                [length]="pendingRecosTotal"
                [pageSize]="Settings.DEFAULT_PAGE_SIZE"
                [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
                [showFirstLastButtons]="true">
              </mat-paginator>
            </div>
          </div>
      </div>
  </div>
</div>

<!-- All Recommendations. -->
<div class="row home-row-top">
  <div class="col-md-12">
      <div class="panel-dark" style="border: unset !important;">
          <div class="panel-heading">
              <span class="panel-title">כל ההמלצות</span>
          </div>
          <div class="panel-body">
            <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
              <table class="table-striped table-responsive-md" mat-table [dataSource]="recosData"
                matSortActive="started" matSortDisableClear matSortDirection="desc" #recosSort="matSort" matSort>
                <!-- Title -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> המלצה </th>
                  <td mat-cell *matCellDef="let reco" class="td-details">
                    <div style="padding-right: 15px;white-space: nowrap;">
                      
                      <p style="margin-top: 3px;margin: 0px 0px 0px !important;padding-bottom: 10px;">
                        <img [src]="reco.game.iconSrc" style="height: 24px; width: 24px;"/>  {{ reco.title }}
                        <span *ngIf="reco.status === RecoStatusTypes.CANCELED">(<span class="pending-text" style="font-size: 15px;">בוטלה</span>)</span>
                        <span *ngIf="reco.status === RecoStatusTypes.ENDED && reco.realResult">(<span class="correct-txt" style="font-size: 15px;">נכונה</span>)</span>
                        <span *ngIf="reco.status === RecoStatusTypes.ENDED && !reco.realResult">(<span class="wrong-txt" style="font-size: 15px;">טועה</span>)</span>
                      </p>
                      <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="reco.ratio1">
                        <img [src]="reco.group1.imageSrc" class="reco-group-img"/>
                        {{ reco.group1.name }} 
                      </span>
                      &nbsp;<b style="font-size: 15px;">.Vs</b>&nbsp;
                      <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="reco.ratio2">
                        <img [src]="reco.group2.imageSrc" class="reco-group-img"/>
                        {{ reco.group2.name }} 
                      </span>
                    </div>
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="creator">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> ממליץ </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ reco.creator.fullName }}
                  </td>
                </ng-container>
                <!-- Recommended Result -->
                <ng-container matColumnDef="result">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תוצאה מומלצת </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    <span *ngIf="reco.result === RecoResultTypes.FIRST_WIN">
                      ניצחון - {{ reco.group1.name }}
                    </span>
                    <span *ngIf="reco.result === RecoResultTypes.TIE">
                      תיקו
                    </span>
                    <span *ngIf="reco.result === RecoResultTypes.SECOND_WIN">
                      ניצחון - {{ reco.group2.name }}
                    </span>
                  </td>
                </ng-container>
                <!-- Time -->
                <ng-container matColumnDef="datetime">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך ושעה </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ this.datePipe.transform(reco.gameTime, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Created -->
                <ng-container matColumnDef="created">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> פורסמה בתאריך </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ this.datePipe.transform(reco.created, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Options -->
                <ng-container matColumnDef="options">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
                  <td mat-cell *matCellDef="let reco">
                    <a href="{{ Settings.WEBISTE_URL }}/reco/{{ reco.id }}" target="_blank">צפייה בהמלצה</a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="recoColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: recoColumns;"></tr>
              </table>
              <!-- Not found items situation. -->
              <div style="margin-right: 10px" *ngIf="recosTotal <= 0">
                <h4>אין המלצות לתצוגה.</h4>
              </div>
              <!-- Paginator setup. -->
              <mat-paginator 
                #recosPaginator
                [length]="recosTotal"
                [pageSize]="Settings.DEFAULT_PAGE_SIZE"
                [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
                [showFirstLastButtons]="true">
              </mat-paginator>
            </div>
          </div>
      </div>
  </div>
</div>