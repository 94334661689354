import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Angular Material
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule, MAT_CHECKBOX_CLICK_ACTION } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MainComponent } from './main/main.component';

// Auth Guards
import { 
  AuthGuardLoggedIn as AuthLoggedIn,
  AuthGuardNotLoggedIn as AuthNotLogin
} from './base/auth.guard';

// Services
import { ChatService } from '../app/services/chat.service';


import { UsersComponent } from './users/users.component';
import { GamesGroupsComponent } from './games-groups/games-groups.component';
import { CreateGameComponent } from './games-groups/games/create-game/create-game.component';
import { EditGameComponent } from './games-groups/games/edit-game/edit-game.component';
import { DeleteGameComponent } from './games-groups/games/delete-game/delete-game.component';
import { CreateGroupComponent } from './games-groups/groups/create-group/create-group.component';
import { EditGroupComponent } from './games-groups/groups/edit-group/edit-group.component';
import { DeleteGroupComponent } from './games-groups/groups/delete-group/delete-group.component';
import { MaterialFileUploadComponent } from './utils/material-file-upload/material-file-upload.component';
import { RecosComponent } from './recos/recos.component';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { BanUserComponent } from './users/ban-user/ban-user.component';
import { UnbanUserComponent } from './users/unban-user/unban-user.component';
import { MarkCorrectRecoComponent } from './recos/mark-correct-reco/mark-correct-reco.component';
import { MarkIncorrectRecoComponent } from './recos/mark-incorrect-reco/mark-incorrect-reco.component';
import { CancelRecoComponent } from './recos/cancel-reco/cancel-reco.component';
import { PaymentsComponent } from './payments/payments.component';
import { AdvsComponent } from './advs/advs.component';
import { CreateAdvComponent } from './advs/create-adv/create-adv.component';
import { EditAdvComponent } from './advs/edit-adv/edit-adv.component';
import { DeleteAdvComponent } from './advs/delete-adv/delete-adv.component';
import { RecommendersComponent } from './recommenders/recommenders.component';
import { ApproveDrawComponent } from './recommenders/approve-draw/approve-draw.component';
import { DeclineDrawComponent } from './recommenders/decline-draw/decline-draw.component';
import { ChatsComponent } from './chats/chats.component';
import { UserPaymentsComponent } from './user-payments/user-payments.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    UsersComponent,
    GamesGroupsComponent,
    CreateGameComponent,
    EditGameComponent,
    DeleteGameComponent,
    CreateGroupComponent,
    EditGroupComponent,
    DeleteGroupComponent,
    MaterialFileUploadComponent,
    RecosComponent,
    EditUserComponent,
    BanUserComponent,
    UnbanUserComponent,
    MarkCorrectRecoComponent,
    MarkIncorrectRecoComponent,
    CancelRecoComponent,
    PaymentsComponent,
    AdvsComponent,
    CreateAdvComponent,
    EditAdvComponent,
    DeleteAdvComponent,
    RecommendersComponent,
    ApproveDrawComponent,
    DeclineDrawComponent,
    ChatsComponent,
    UserPaymentsComponent
  ],
  imports: [
    BrowserModule, 
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    [DatePipe],
    [AuthLoggedIn],
    [AuthNotLogin],
    [ChatService]
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    CreateGameComponent,
    EditGameComponent,
    DeleteGameComponent,
    CreateGroupComponent,
    EditGroupComponent
  ]
})
export class AppModule { }
