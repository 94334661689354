export class ServerRoutes {
  // Users
  public static LOGIN = "/users/login";

  // Admins
  public static AUTHENTICATE = "/admins/authenticate";

  // Users
  public static EDIT_USER = "/admins/users/edit";
  public static BAN_USER = "/admins/users/ban";
  public static UNBAN_USER = "/admins/users/unban";

  // Games
  public static CREATE_GAME = "/admins/games/create";
  public static EDIT_GAME = "/admins/games/edit";
  public static DELETE_GAME = "/admins/games/delete";

  // Groups
  public static CREATE_GROUP = "/admins/groups/create";
  public static EDIT_GROUP = "/admins/groups/edit";
  public static DELETE_GROUP = "/admins/groups/delete";

  // Advertisments
  public static CREATE_ADVERTISMENT = "/admins/banners/create";
  public static EDIT_ADVERTISMENT = "/admins/advs/edit"; 
  public static DELETE_ADVERTISMENT = "/admins/advs/delete";

  // Recos
  public static CANCEL_RECO = "/admins/recos/cancel";
  public static MARK_RECO = "/admins/recos/mark";

  // Payments
  public static COMPLETE_DRAW_REQUEST = "/admins/draws/complete";

  // Chats
  public static GET_PRIVATE_SUPPORT_CHAT = "/chats/admins/support/conversation";
  public static GET_SUPPORT_CONVERSATIONS = "/chats/support/conversations";
  public static SEND_SUPPORT_CHAT = "/chats/admins/support/send";
  
  // Data
  public static MAIN_DATA = "/data/page/admin/main";
  public static GLOBAL_TYPES = "/data/global/types";
  public static RECOS = "/data/table/admin/recos";
  public static GET_USERS = "/data/table/admin/users";
  public static GET_GROUPS = "/data/table/admin/groups";
  public static GET_PAYMENTS = "/data/table/admin/payments";
  public static GET_ADVERTISEMENTS = "/data/table/admin/advertisements";
  public static GET_PAYMENTS_TRACK = "/data/table/admin/paymentstrack";

  // Images
  public static UPLOAD_TEMP_IMAGE = "/resources/temp/upload";
  public static VIEW_TEMP_IMAGE = "/resources/temp/view";
}

export class AppRoutes {
  public static MAIN_INDEX = "";
  public static USERS_MANAGEMENT = "users";
  public static GAMES_GROUPS = "games-groups";
  public static RECOS = "recos";
  public static PAYMENTS = "payments";
  public static ADVERTISEMENTS = "advs";
  public static RECOMMENDERS = "recommenders";
  public static CHATS = "chats";
  public static USER_PAYMENTS = "user-payments/:id";
}

export class ExternalRoutes {
  public static OVERSIGHT = "https://oversight.co.il";
  public static FACEBOOK = "https://www.facebook.com/Winvice-2041539992598407";
  public static TWITTER = "";
}