// Angular libraries
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Global
import * as Settings from '../../global/settings';
import { ServerRoutes } from '../../global/routes';

// Shared
import { StatusCodes } from '../../../../../shared/status.codes';

// Models
import * as GlobalModels from '../../models/global.models';
import * as RecoModels from '../../models/reco.models';

// Systems
import { Notifications } from '../../systems/notifications';
import { Recos } from '../../systems/recos';
import { NONE_TYPE } from '@angular/compiler';

import * as Formats from '../../utils/formats';

import { markRecoNotifications } from '../mark-correct-reco/mark-correct-reco.component'

@Component({
  selector: 'app-mark-incorrect-reco',
  templateUrl: './mark-incorrect-reco.component.html',
  styleUrls: ['../dialogs.style.scss']
})
export class MarkIncorrectRecoComponent implements OnInit {

  Formats = Formats;

  private notifications: Notifications;
  private recos: Recos;

  recoMarked: RecoModels.Reco;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MarkIncorrectRecoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RecoModels.Reco) { 

      this.recoMarked = data;
      this.recos = new Recos(this.http);
      this.notifications = new Notifications(snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);
   }

  ngOnInit(): void {
  }

  onMarkIncorrectSubmitted(): void {
    this.recos.MarkReco(this.recoMarked.id, false).subscribe(
      (data) => {
        this.notifications.Ok(markRecoNotifications[StatusCodes.OK]);
        this.dialogRef.close(data);
      },
      (err) => {
        this.notifications.Error(err.error.error_code === undefined ? 
          markRecoNotifications[StatusCodes.INTERNAL] : markRecoNotifications[err.error.error_code]);
      }
    );
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }


}
