<button mat-button type="button" color="default" class="upload-btn" (click)="onClick()">
  {{text}}
  <mat-icon>file_upload</mat-icon>
</button>
<br/>
<ul>
  <li *ngFor="let file of files" [@fadeInOut]="file.state">
    <mat-progress-bar [value]="file.progress"></mat-progress-bar>
    <br>
    <span id="file-label">
      {{file.data.name}}
      <a title="Retry" (click)="retryFile(file)">
      <mat-icon>refresh</mat-icon></a>
    </span>
  </li>
</ul>
<input type="file" id="fileUpload" name="fileUpload" accept="{{accept}}" style="display:none;"/>
