// Angular libraries
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// Global
import * as Settings from '../global/settings';

// Shared
import { StatusCodes } from '../../../../shared/status.codes';

// Utilities
import * as Formats from '../utils/formats';

// Models
import * as UserModels from '../models/user.models';

// Systems
import { Notifications } from '../systems/notifications';
import { Users } from '../systems/users';

// Componenets
import { EditUserComponent } from './edit-user/edit-user.component';
import { BanUserComponent } from './ban-user/ban-user.component';
import { UnbanUserComponent } from './unban-user/unban-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit, AfterViewInit {

  // Implements
  Settings = Settings;
  Formats = Formats;
  UserPrivileges = UserModels.PrivilegeTypes;
  UserStatusTypes = UserModels.StatusTypes;

  // Systems
  private users: Users;
  private notifications: Notifications;

  // Flags
  activeUsersBadSearch: string = "";

  // Active Users
  activeUsersLoading = true;
  activeUsersData: UserModels.User[] = [];
  activeUsersColumns: string[] = [ 
    'fullName',
    'mobile',
    'created', 
    'options'
  ];
  totalActiveUsers = Settings.DEFAULT_LOADED_VALUE;

  // Paginators
  @ViewChild('activeUsersPaginator') activeUsersPaginator: MatPaginator;
  
  // Sorts
  @ViewChild('activeUsersSort') activeUsersSort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private http: HttpClient,
    private dialog: MatDialog) {

      this.users = new Users(this.http);

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // =================-- [ Active Users ] --=================
    Formats.translatePaginator(this.activeUsersPaginator);

    merge(this.activeUsersSort.sortChange, this.activeUsersPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.activeUsersLoading = true;
          return this.users!.GetUsers<UserModels.User[]>(this.activeUsersPaginator.pageIndex + 1, this.activeUsersPaginator.pageSize, false, "");
        }),
        map(data => {
          this.activeUsersLoading = false;
          this.totalActiveUsers = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          this.activeUsersLoading = false;
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.activeUsersData = data.reverse();
      }
    );
  }

  filterUsers(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.replace("-", "");
    if(/\S/.test(filterValue) || filterValue == "") {
      this.activeUsersLoading = true;
      this.users!.GetUsers<any>(this.activeUsersPaginator.pageIndex + 1, this.activeUsersPaginator.pageSize, false, filterValue).pipe(
        map((data) => { 
          this.activeUsersBadSearch = "";
          return data.items; 
        }),
        catchError((err) => { 
          this.activeUsersBadSearch = filterValue;
          return observableOf([]); 
        })
      ).subscribe((data) => { 
        this.activeUsersLoading = false;
        this.activeUsersData = data.reverse();
      });
    }
  }

  onEditUserDialog(user: UserModels.User): void {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '400px',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        let idx = this.activeUsersData.findIndex(i => i === user);
        this.activeUsersData[idx] = result;
        this.ngAfterViewInit();
      }
    });
  }

  onBanUserDialog(user: UserModels.User): void {
    const dialogRef = this.dialog.open(BanUserComponent, {
      width: '400px',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        let idx = this.activeUsersData.findIndex(i => i === user);
        this.activeUsersData[idx] = result;
        this.ngAfterViewInit();
      }
    });
  }

  onUnBanUserDialog(user: UserModels.User): void {
    const dialogRef = this.dialog.open(UnbanUserComponent, {
      width: '400px',
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        let idx = this.activeUsersData.findIndex(i => i === user);
        this.activeUsersData[idx] = result;
        this.ngAfterViewInit();
      }
    });
  }
}
