import * as RecoModels from './reco.models';

export enum PaymentTypes {
  NONE = 0,
  CC = 1,
  MANUALY = 2
};

export enum StatusTypes {
  NONE = 0,
  PENDING = 1,
  FAILED = 2,
  PAIED = 3,
  CANCELED = 4,
  WRONG = 5
};

export enum DrawStatusTyepes {
  NONE = 0,
  PENDING = 1,
  ATTRACTED = 2
};

export interface AdminViewModel {
  userID: number;
  description: string;
  status: StatusTypes;
  userName: string;
  phone: string;
  created: Date;
  amount: number;
  type: PaymentTypes;
  receiptURL: string;
  lastDigits: string;
  reco: RecoModels.Reco;
}

export enum PaymentTrackStatusTypes {
  NONE = 0,
  PENDING = 1,
  APPROVED = 2,
  DECLINED = 3
};

export interface PaymentTrack {
  id: number;
  userID: number;
  userName: string;
  created: Date;
  amount: number;
  status: PaymentTrackStatusTypes;
}

export interface DrawInfo {
  id: number;
  user: string;
  amount: number;
};