// Angular libraries
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from, merge, Observable, of as observableOf } from 'rxjs';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

// Global
import * as Settings from '../global/settings';

// Shared
import { StatusCodes } from '../../../../shared/status.codes';

// Utilities
import * as Formats from '../utils/formats';

// Models
import * as GlobalModels from '../models/global.models';

// Systems
import { Notifications } from '../systems/notifications';
import { Global } from '../systems/global';

// Componenets
import { CreateAdvComponent } from './create-adv/create-adv.component';
import { EditAdvComponent } from './edit-adv/edit-adv.component';
import { DeleteAdvComponent } from './delete-adv/delete-adv.component';

@Component({
  selector: 'app-advs',
  templateUrl: './advs.component.html',
  styleUrls: ['./advs.component.scss']
})
export class AdvsComponent implements OnInit, AfterViewInit {

  // Implements
  Settings = Settings;
  Formats = Formats;
  DisplayTypes = GlobalModels.AdvDisplayTypes;

  // Systems
  private global: Global;

  // Groups
  advsData: GlobalModels.Adv[] = [];
  advsColumns: string[] = [ 
    'title',
    'image',
    'display',
    'clicks',
    'options'
  ];
  advsTotal = Settings.DEFAULT_LOADED_VALUE;

  // Paginators
  @ViewChild('advsPaginator') advsPaginator: MatPaginator;

  // Sorts
  @ViewChild('advsSort') advsSort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private http: HttpClient) {

      this.global = new Global(this.http, true);
    }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // =================-- [ Active Advertisments ] --=================
    Formats.translatePaginator(this.advsPaginator);

    merge(this.advsSort.sortChange, this.advsPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.global.GetAdvertisementsTable(this.advsPaginator.pageIndex + 1, this.advsPaginator.pageSize);
        }),
        map(data => {
          this.advsTotal = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.advsData = data;
      }
    );
  }
  

  onCreateAdvDialogShow(): void {
    const dialogRef = this.dialog.open(CreateAdvComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        this.ngAfterViewInit();
      }
    });
  }

}
