// Angular libraries
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';

// Global
import * as Settings from '../../../global/settings';
import { ServerRoutes } from '../../.././global/routes';

// Shared
import { StatusCodes } from '../../../../../../shared/status.codes';

// Models
import * as GlobalModels from '../../../models/global.models';

// Systems
import { Notifications } from '../../../systems/notifications';
import { Global } from '../../../systems/global';

// Notifications
const createGameNotifications = {
  [StatusCodes.INTERNAL]: "‫אופס! נתקלנו בשגיאה. אנא נסה שוב בהמשך.‬‎",
  [StatusCodes.INVALID_TOKEN]: "הזדהה בכדי לבצע פעולה זו.",
  [StatusCodes.FIELDS_REQURIED]: "שגיאה במילוי השדות.",
  [StatusCodes.EXTENSION_NOT_ALLOWED]: "הקובץ שבחרת לא מתאים לדרישות.",
  [StatusCodes.INVALID_TYPE_NAME]: "שם המשחק לא תקין.",
  [StatusCodes.TYPE_ALREADY_EXISTS]: "שם המשחק שהזנת כבר קיים.",
  [StatusCodes.OK]: "המשחק נוצר בהצלחה!"
};


@Component({
  selector: 'app-create-game',
  templateUrl: './create-game.component.html',
  styleUrls: ['../../dialogs.style.scss']
})
export class CreateGameComponent implements OnInit {

  createGameForm: FormGroup;
  
  private notifications: Notifications;
  private global: Global;

  loading = false;
  submitted = false;

  uploadImageHash = "";

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateGameComponent>) {

      this.global = new Global(this.http, true);
      this.notifications = new Notifications(snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);

      this.createGameForm = this.formBuilder.group({
        name: ['', [Validators.required]]
      });
    }

  ngOnInit(): void {
  }

  get fc() {
    return this.createGameForm.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const fileName = event.target.files[0].name;

      var re = /(?:\.([^.]+))?$/;

      if(!Settings.ALLOWED_IMAGE_EXTENSIONS.includes(re.exec(fileName)[1])) {
        this.notifications.Error(createGameNotifications[StatusCodes.EXTENSION_NOT_ALLOWED]);
        return;
      }
    }
  }

  onFileComplete(fileHash: string): void {
    this.uploadImageHash = `${Settings.SERVER_URL}${ServerRoutes.VIEW_TEMP_IMAGE}/${fileHash}`;
  }


  onCreateSubmitted(): void {
    this.submitted = true;

    if(this.createGameForm.invalid) {
      this.notifications.Error(createGameNotifications[StatusCodes.FIELDS_REQURIED]);
      return;
    }

    this.loading = true;

    let imageHash = "";
    if(this.uploadImageHash.length > 0)
      imageHash = this.uploadImageHash.slice(this.uploadImageHash.lastIndexOf("/") + 1);

    this.global.CreateGame(this.fc.name.value, imageHash).subscribe(
      (data: GlobalModels.Game) => {
        this.notifications.Ok(createGameNotifications[StatusCodes.OK]);
        this.dialogRef.close(data);
      },
      (err) => {
        this.notifications.Error(err.error.error_code === undefined ? 
          createGameNotifications[StatusCodes.INTERNAL] : createGameNotifications[err.error.error_code]);
      }
    );
  }
  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
