// Angular libraries
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Global
import * as Settings from '../../../global/settings';
import { ServerRoutes } from '../../.././global/routes';

// Shared
import { StatusCodes } from '../../../../../../shared/status.codes';

// Models
import * as GlobalModels from '../../../models/global.models';

// Systems
import { Notifications } from '../../../systems/notifications';
import { Global } from '../../../systems/global';

// Notifications
const createGroupNotifications = {
  [StatusCodes.INTERNAL]: "‫אופס! נתקלנו בשגיאה. אנא נסה שוב בהמשך.‬‎",
  [StatusCodes.INVALID_TOKEN]: "הזדהה בכדי לבצע פעולה זו.",
  [StatusCodes.FIELDS_REQURIED]: "שגיאה במילוי השדות.",
  [StatusCodes.EXTENSION_NOT_ALLOWED]: "הקובץ שבחרת לא מתאים לדרישות.",
  [StatusCodes.GAME_NOT_EXISTS]: "משחק לא נמצא.",
  [StatusCodes.INVALID_TYPE_NAME]: "שם הקבוצה לא תקין.",
  [StatusCodes.TYPE_ALREADY_EXISTS]: "הקבוצה כבר קיימת.",
  [StatusCodes.OK]: "הקבוצה נוצרה בהצלחה!"
};

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['../../dialogs.style.scss']
})
export class CreateGroupComponent implements OnInit {

  createGroupForm: FormGroup;
  gamesOptions: GlobalModels.Game[] = [];
  
  private notifications: Notifications;
  private global: Global;

  submitted = false;
  loading = false;

  uploadImageHash = "";

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GlobalModels.Game[]) { 
      
    this.gamesOptions = data;
    this.global = new Global(this.http, true);
    this.notifications = new Notifications(snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);

    this.createGroupForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      game: ['', [Validators.required]]
    });
  }


  ngOnInit(): void {
  }

  get fc() {
    return this.createGroupForm.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const fileName = event.target.files[0].name;

      var re = /(?:\.([^.]+))?$/;

      if(!Settings.ALLOWED_IMAGE_EXTENSIONS.includes(re.exec(fileName)[1])) {
        this.notifications.Error(createGroupNotifications[StatusCodes.EXTENSION_NOT_ALLOWED]);
        return;
      }
    }
  }

  onFileComplete(fileHash: string): void {
    this.uploadImageHash = `${Settings.SERVER_URL}${ServerRoutes.VIEW_TEMP_IMAGE}/${fileHash}`;
  }


  onCreateGroupSubmitted(): void {
    this.submitted = true;

    if(this.createGroupForm.invalid) {
      this.notifications.Error(createGroupNotifications[StatusCodes.FIELDS_REQURIED]);
      return;
    }

    this.loading = true;

    let imageHash = "";
    if(this.uploadImageHash.length > 0)
      imageHash = this.uploadImageHash.slice(this.uploadImageHash.lastIndexOf("/") + 1);
    
    if(!this.gamesOptions.some(i => i.id === +this.fc.game.value)) {
      this.notifications.Error(createGroupNotifications[StatusCodes.GAME_NOT_EXISTS]);
      return;
    }

    this.global.CreateGroup(this.fc.name.value, +this.fc.game.value, imageHash).subscribe(
      (data: GlobalModels.Group) => {
        this.notifications.Ok(createGroupNotifications[StatusCodes.OK]);
        this.dialogRef.close(data);
      },
      (err) => {
        this.notifications.Error(err.error.error_code === undefined ? 
          createGroupNotifications[StatusCodes.INTERNAL] : createGroupNotifications[err.error.error_code]);
      }
    );
  }
  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
