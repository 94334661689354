import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

import { isResposiveWindow } from '../utils/detect';

import * as Settings from '../global/settings';

export class Notifications {
private _snackBar: MatSnackBar;
private _closeText: string;
private _duration: number;

constructor(snackBar: MatSnackBar, closeText: string, duration: number) {
  this._snackBar = snackBar;
  this._closeText = closeText;
  this._duration = duration;
}

private DisplaySnackBar(message: string, themeClass: string, closeText = this._closeText) {
  let isResp = isResposiveWindow();

  this._snackBar.open(message, closeText, {
    duration: this._duration,
    horizontalPosition: !isResp ? Settings.NOTIF_HORZ_POS : Settings.NOTIF_RESPONSIVE_HORZ_POS,
    verticalPosition: !isResp ? Settings.NOTIF_VERT_POS : Settings.NOTIF_RESPONSIVE_VERT_POS,
    panelClass: themeClass
  });
}

Error(message: string) {
  this.DisplaySnackBar(message, "failed");
}

Ok(message: string) {
  this.DisplaySnackBar(message, "success");
}

Info(message: string, closeText = "") {
  this.DisplaySnackBar(message, "info", closeText);
}
}