<!--=================-- [ Unban User ] --=================-->
<div class="dialog-inner-content">
  <h1 class="dialog-title" mat-dialog-title>
    הורדת הרחקה
  </h1>
  <div mat-dialog-content>
    לבטל את ההרחקה של <b>{{ bannedUser.fullName }}</b>?
  </div>
</div>
<div mat-dialog-actions style="text-align:left">
  <button mat-button class="btn-close" (click)="onCloseDialog()">יציאה</button>
  <button (click)="onUnBanUserSubmitted()" class="dialog-btn btn-continue" mat-button>
    המשך
  </button>
</div>