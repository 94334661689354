export interface AuthModel {
  phone: string;
  _authToken: string;
}

export enum StatusTypes {
  NONE = 0,
  PENDING = 1,
  DECLINED = 2,
  ACTIVE = 3,
  SUSPENDED = 4,
  INACTIVE = 5
};


export enum PrivilegeTypes {
  NONE = 0,
  STAFF = 1,
  ADMIN = 2
};

export interface User {
  id: number;
  fullName: string;
  created: Date;
  privilege: PrivilegeTypes;
  phone: string;
  status: StatusTypes;
  recommend: boolean;
};

export interface Recommend extends User {
  successRate: number;
  rating: number;
  plan: string;
  amount: number;
}

export interface RecommendModel {

  id: number;
  fullName: string;
  email: string;
  image: string;
  about: string;
  subscribers: number;
  recos: number;
  created: Date;
  ratingStars: number;
  successRate: number;
  topics: string[];
}