// Angular libraries
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Global
import * as Settings from '../../global/settings';
import { ServerRoutes } from '../../global/routes';

// Shared
import { StatusCodes } from '../../../../../shared/status.codes';

// Models
import * as GlobalModels from '../../models/global.models';
import * as UserModels from '../../models/user.models';

// Systems
import { Notifications } from '../../systems/notifications';
import { Users } from '../../systems/users';
import { NONE_TYPE } from '@angular/compiler';

import * as Formats from '../../utils/formats';

// Notifications
const unBanUserNotifications = {
  [StatusCodes.INTERNAL]: "‫אופס! נתקלנו בשגיאה. אנא נסה שוב בהמשך.‬‎",
  [StatusCodes.INVALID_TOKEN]: "הזדהה בכדי לבצע פעולה זו.",
  [StatusCodes.OBJECT_NOT_FOUND]: "משתמש לא נמצא.",
  [StatusCodes.USER_NOT_BANNED]: "משתמש לא מורחק.",
  [StatusCodes.OK]: "ההרחקה בוטלה בהצלחה!"
};


@Component({
  selector: 'app-unban-user',
  templateUrl: './unban-user.component.html',
  styleUrls: ['../dialogs.style.scss']
})
export class UnbanUserComponent implements OnInit {

  Formats = Formats;

  private notifications: Notifications;
  private users: Users;

  bannedUser: UserModels.User;

  loading = false;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<UnbanUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModels.User) { 

      this.bannedUser = data;
      this.users = new Users(this.http);
      this.notifications = new Notifications(snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);
   }


   ngOnInit(): void {
  }

  onUnBanUserSubmitted(): void {
    this.users.UnBanUser(this.bannedUser.id).subscribe(
      (data) => {
        this.notifications.Ok(unBanUserNotifications[StatusCodes.OK]);
        this.dialogRef.close(data);
      },
      (err) => {
        this.notifications.Error(err.error.error_code === undefined ? 
          unBanUserNotifications[StatusCodes.INTERNAL] : unBanUserNotifications[err.error.error_code]);
      }
    )
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
