<!--=================-- [ Edit User ] --=================-->
<form [formGroup]="editUserForm">
  <div class="dialog-inner-content">
    <!-- Dialog Title -->
    <h1 class="dialog-title" mat-dialog-title>
      עריכת משתמש #{{ Formats.zeroPad(editedUserID, 4) }}
    </h1>
    <div mat-dialog-content>
      <!-- Image Uploaded 
      <div style="text-align: center;margin-bottom: 6px;">
        <img [src]="groupEdited.imageSrc" style="width: 24px; height: 24px">
      </div> -->
      <!-- First Name -->
      <mat-form-field class="dialog-form-field" dir="rtl">
        <mat-label for="firstName">שם פרטי</mat-label>
        <input [ngClass]="{ 'is-invalid': submitted && fc.firstName.errors }" formControlName="firstName" matInput />
        <mat-error *ngIf="fc.firstName.hasError('required')">
          <strong>הזן שם פרטי.</strong>
        </mat-error>
      </mat-form-field>
      <!-- Last Name -->
      <mat-form-field class="dialog-form-field" dir="rtl">
        <mat-label for="lastName">שם משפחה</mat-label>
        <input [ngClass]="{ 'is-invalid': submitted && fc.lastName.errors }" formControlName="lastName" matInput />
        <mat-error *ngIf="fc.lastName.hasError('required')">
          <strong>הזן שם משפחה.</strong>
        </mat-error>
      </mat-form-field>
      <!-- Phone -->
      <mat-form-field class="dialog-form-field" dir="rtl">
        <mat-label for="phone">מספר פלאפון</mat-label>
        <input matInput formControlName="phone" [ngClass]="{ 'is-invalid': submitted && fc.phone.errors }">
        <mat-error *ngIf="fc.phone.hasError('required')">
          <strong>הזן מספר פלאפון</strong>
        </mat-error>
        <mat-error *ngIf="fc.phone.hasError('minlength') || fc.phone.hasError('maxlength')">
          <strong>הזן מספר פלאפון תקין</strong>
        </mat-error>
      </mat-form-field>
      <!-- Permissions -->
      <mat-form-field class="dialog-form-field" dir="rtl">
        <mat-label for="perms">הרשאות</mat-label>
        <mat-select [ngClass]="{ 'is-invalid': submitted && fc.perms.errors }" formControlName="perms">
          <mat-option [value]="PrivilegeTypes.NONE">משתמש רגיל</mat-option>
          <mat-option [value]="PrivilegeTypes.STAFF">צוות</mat-option>
          <mat-option [value]="PrivilegeTypes.ADMIN">אדמין</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="row">

      <div class="col-md-6">
        <mat-form-field class="dialog-form-field" dir="rtl">
          <mat-label for="password">סיסמא חדשה</mat-label>
          <input type="password" matInput [ngClass]="{ 'is-invalid': submitted && fc.password.errors }" formControlName="password">
          <mat-error *ngIf="fc.password.hasError('minlength')">
            <strong>סיסמא קצרה מדי</strong>
          </mat-error>
          <mat-error *ngIf="fc.password.hasError('maxlength')">
            <strong>סיסמא ארוכה מדי</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="dialog-form-field" dir="rtl">
          <mat-label for="confirmPassword">אימות סיסמא</mat-label>
          <input type="password" matInput [ngClass]="{ 'is-invalid': submitted && fc.confirmPassword.errors }" formControlName="confirmPassword">
          <mat-error *ngIf="fc.confirmPassword.hasError('mustMatch')">
              <strong>הסיסמאות אינן תואמות</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Password -->
      <!-- Confirm Password -->
    </div>
    </div>
  </div>
  <!-- Dialog Actions -->
  <div mat-dialog-actions style="text-align:left">
    <!-- Close -->
    <button mat-button class="btn-close" (click)="onCloseDialog()">יציאה</button>
    <!-- Submit -->
    <button (click)="onEditUserSubmitted()" class="dialog-btn btn-continue" mat-button>
      שמירה
    </button>
  </div>
</form>