import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutes } from './global/routes';

// Auth Guards
import { 
  AuthGuardLoggedIn as AuthLoggedIn,
  AuthGuardNotLoggedIn as AuthNotLogin,
} from './base/auth.guard'; 

// Components
import { MainComponent } from './main/main.component';
import { UsersComponent } from './users/users.component';
import { GamesGroupsComponent } from './games-groups/games-groups.component';
import { RecosComponent } from './recos/recos.component';
import { PaymentsComponent } from './payments/payments.component';
import { AdvsComponent } from './advs/advs.component';
import { RecommendersComponent } from './recommenders/recommenders.component';
import { ChatsComponent } from './chats/chats.component';

const routes: Routes = [
  {
    path: AppRoutes.MAIN_INDEX,
    component: MainComponent,
  },
  {
    path: AppRoutes.USERS_MANAGEMENT,
    component: UsersComponent
  },
  {
    path: AppRoutes.GAMES_GROUPS,
    component: GamesGroupsComponent
  },
  {
    path: AppRoutes.RECOS,
    component: RecosComponent
  },
  {
    path: AppRoutes.PAYMENTS,
    component: PaymentsComponent
  },
  {
    path: AppRoutes.ADVERTISEMENTS,
    component: AdvsComponent
  },
  {
    path: AppRoutes.RECOMMENDERS,
    component: RecommendersComponent
  },
  {
    path: AppRoutes.CHATS,
    component: ChatsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
