// Angular libraries
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// Global
import * as Settings from '../global/settings';

// Shared
import { StatusCodes } from '../../../../shared/status.codes';

// Utilities
import * as Formats from '../utils/formats';

// Models
import * as MainModels from '../models/main.models';
import * as PaymentModels from '../models/payment.models';

// Systems
import { Global } from '../systems/global';
import { Notifications } from '../systems/notifications';
import { Payments } from '../systems/payments';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, AfterViewInit {

  Formats = Formats;
  Settings = Settings;
  PaymentTypes = PaymentModels.PaymentTypes;

  private notifications: Notifications;
  private payments: Payments;

  // Pending Transactions.
  pendingPaymentsLoading = true;
  pendingPaymentsData: PaymentModels.AdminViewModel[] = [];
  pendingPaymentsColumns: string[] = [ 
    'user',
    'desc',
    'amount',
    'created', 
    'type',
    'options' 
  ];
  pendingPaymentsLength = Settings.DEFAULT_LOADED_VALUE;

  // Payments History
  paymentsHistoryLoading = true;
  paymentsHistoryData: PaymentModels.AdminViewModel[] = [];
  paymentsHistoryLength = Settings.DEFAULT_LOADED_VALUE;
  paymentsHistoryColumns: string[] = [ 
    'user',
    'desc',
    'amount',
    'created', 
    'type',
    'status',
    'options' 
  ];

  // Payments Failed
  paymentsFailedLoading = true;
  paymentsFailedData: PaymentModels.AdminViewModel[] = [];
  paymentsFailedLength = Settings.DEFAULT_LOADED_VALUE;
  paymentsFailedColumns: string[] = [ 
    'user',
    'desc',
    'amount',
    'created', 
    'type',
    'status'
  ];

  // Paginators
  @ViewChild('pendingPaymentsPaginator') pendingPaymentsPaginator: MatPaginator;
  @ViewChild('paymentsHistoryPaginator') paymentsHistoryPaginator: MatPaginator;
  @ViewChild('paymentsFailedPaginator') paymentsFailedPaginator: MatPaginator;
  
  // Sorts
  @ViewChild('pendingPaymentsSort') pendingPaymentsSort: MatSort;
  @ViewChild('paymentsHistorySort') paymentsHistorySort: MatSort;
  @ViewChild('paymentsFailedSort') paymentsFailedSort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog, 
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private http: HttpClient) {

    this.payments = new Payments(this.http);
    this.notifications = new Notifications(this.snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // =================-- [ Pending Transactions ] --=================
    Formats.translatePaginator(this.pendingPaymentsPaginator);

    merge(this.pendingPaymentsSort.sortChange, this.pendingPaymentsPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.pendingPaymentsLoading = true;
          return this.payments!.GetPayments(PaymentModels.StatusTypes.PENDING, PaymentModels.PaymentTypes.MANUALY, this.pendingPaymentsPaginator.pageIndex + 1, this.pendingPaymentsPaginator.pageSize);
        }),
        map(data => {
          this.pendingPaymentsLoading = false;
          this.pendingPaymentsLength = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          this.pendingPaymentsLoading = false;
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.pendingPaymentsData = data;
      }
    );

    // =================-- [ Payments History ] --=================
    Formats.translatePaginator(this.paymentsHistoryPaginator);

    merge(this.paymentsHistorySort.sortChange, this.paymentsHistoryPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.paymentsHistoryLoading = true;
          return this.payments!.GetPayments(PaymentModels.StatusTypes.NONE, PaymentModels.PaymentTypes.NONE, this.paymentsHistoryPaginator.pageIndex + 1, this.paymentsHistoryPaginator.pageSize);
        }),
        map(data => {
          this.paymentsHistoryLoading = false;
          this.paymentsHistoryLength = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          this.paymentsHistoryLoading = false;
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.paymentsHistoryData = data;
        console.log(data);
      }
    );

    // =================-- [ Payments Failed ] --=================
    Formats.translatePaginator(this.paymentsFailedPaginator);

    merge(this.paymentsFailedSort.sortChange, this.paymentsFailedPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.paymentsFailedLoading = true;
          return this.payments!.GetPayments(PaymentModels.StatusTypes.FAILED, PaymentModels.PaymentTypes.CC, this.paymentsFailedPaginator.pageIndex + 1, this.paymentsFailedPaginator.pageSize);
        }),
        map(data => {
          this.paymentsFailedLoading = false;
          this.paymentsFailedLength = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          this.paymentsFailedLoading = false;
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.paymentsFailedData = data;
      }
    );
  }

}
