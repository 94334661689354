<!-- Stats -->
<div class="row">
  <div class="col-md-12">
    <h2 class="ls-top-header">עמוד ראשי</h2>
  </div>
</div>
<!-- Registered Users. -->
<div class="row home-row-top">
  <div class="col-md-3 col-sm-6">
    <div class="ls-wizard label-light-green" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;border: unset !important;">
      <h2>משתמשים רשומים: {{ mainData.stats_registeredUsers }}</h2>
      <div class="icon">
          <i class="fa fa-users"></i>
      </div>
    </div>
  </div>
  <!-- Posted Recommendations. -->
  <div class="col-md-3 col-sm-6">
    <div class="ls-wizard label-red" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;border: unset !important;">
      <h2>המלצות שפורסמו:  {{ mainData.stats_postedRecommendations }}</h2>
      <div class="icon">
          <i class="fa fa-download"></i>
      </div>
    </div>
  </div>
  <!-- Purchases. -->
  <div class="col-md-3 col-sm-6">
    <div class="ls-wizard label-lightBlue margin-top-iPad-15" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;border: unset !important;">
      <h2>רכישות:  {{ mainData.stats_acceptedPurchases }}</h2>
      <div class="icon">
          <i class="fa fa-shopping-cart"></i>
      </div>
    </div>
  </div>
  <!-- Total Financial. -->
  <div class="col-md-3 col-sm-6">
    <div class="ls-wizard label-light-green margin-top-iPad-15" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;border: unset !important;">
      <h2>הכנסות סה"כ: {{ mainData.stats_incomeFinancial }}&#8362;</h2>
      <div class="icon">
        <i class="fa fa-dollar"></i>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <!-- Last monthly success percentage. -->
  <div class="col-md-9">
      <div class="memberBox" style="box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;border: unset !important;">
          <div class="memberBox-header">
              <h5>אחוזי הצלחה בחודשים האחרונים</h5>
          </div>
          <div class="monthlySale">
          </div><br /><br />
      </div>
  </div>
  <!-- Latest registered users.  -->
  <div class="col-md-3">
    <div class="current-status-widget">
      <ul>
        <li *ngFor="let i of mainData.lastRegistered">
          <div class="status-box">
            <div class="status-box-icon label-default white">
              <i class="fa fa-user"></i>
            </div>
            <div class="status-box-content">
              <h5 id="sale-view">{{ i }}</h5>
              <p class="lightGreen"><i class="fa fa-sign-in lightGreen"></i> נרשם לאתר</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Open Recommendations. -->
<div class="row home-row-top">
  <div class="col-md-12">
      <div class="panel-dark" style="border: unset !important;">
          <div class="panel-heading">
              <span class="panel-title">המלצות פתוחות</span>
          </div>
          <div class="panel-body">
            <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
              <table class="table-striped table-responsive-md" mat-table [dataSource]="openRecosData"
                matSortActive="started" matSortDisableClear matSortDirection="desc" #recosSort="matSort" matSort>
                <!-- Title -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> המלצה </th>
                  <td mat-cell *matCellDef="let reco" class="td-details">
                    <div style="padding-right: 15px;white-space: nowrap;">
                      <p style="margin-top: 3px;margin: 0px 0px 0px !important;padding-bottom: 10px;">
                        <img [src]="reco.game.iconSrc" style="height: 24px; width: 24px;"/>  {{ reco.title }}
                      </p>
                      <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="reco.ratio1">
                        <img [src]="reco.group1.imageSrc" class="reco-group-img"/>
                        {{ reco.group1.name }} 
                      </span>
                      &nbsp;<b style="font-size: 15px;">.Vs</b>&nbsp;
                      <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="reco.ratio2">
                        <img [src]="reco.group2.imageSrc" class="reco-group-img"/>
                        {{ reco.group1.name }} 
                      </span>
                    </div>
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="creator">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> ממליץ </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ reco.creator.fullName }}
                  </td>
                </ng-container>
                <!-- Recommended Result -->
                <ng-container matColumnDef="result">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תוצאה מומלצת </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    ניצחון - אוקלהומה ת'אנדר
                  </td>
                </ng-container>
                <!-- Time -->
                <ng-container matColumnDef="datetime">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך ושעת המשחק </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    {{ this.datePipe.transform(reco.gameTime, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Options -->
                <ng-container matColumnDef="options">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
                  <td mat-cell *matCellDef="let reco">
                    <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                    <mat-menu #menu="matMenu">
                      <button class="reco-option-btn" (click)="navigateToEditReco(reco.id)" mat-menu-item>
                        עריכה&nbsp;&nbsp;<i class="fa fa-edit" style="font-size: 16px;"></i>
                      </button>
                      <button class="reco-option-btn" (click)="onMarkCorrectDialogShow(reco)" mat-menu-item>
                        אישור תוצאה&nbsp;&nbsp;
                        <i class="fa fa-check" style="font-size: 16px;color:seagreen"></i>
                      </button>
                      <button class="reco-option-btn" (click)="onMarkIncorrectDialogShow(reco)" mat-menu-item>
                        סימון טועה&nbsp;&nbsp;
                        <i class="fa fa-times" style="font-size: 16px;color:#901d28"></i>
                      </button>
                      <button class="reco-option-btn" (click)="onCancelRecoDialogShow(reco)" mat-menu-item>
                        ביטול&nbsp; 
                        <i class="fa fa-ban" style="font-size: 16px;"></i>
                      </button>
                    </mat-menu>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="recoColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: recoColumns;"></tr>
              </table>
              <!-- Not found items situation. -->
              <div style="margin-right: 10px">
                <h4 *ngIf="recosTotalItems <= 0">אין המלצות פתוחות כרגע.</h4>
              </div>
              <!-- Paginator setup. -->
              <mat-paginator 
                #recosPaginator
                [length]="recosTotalItems"
                [pageSize]="Settings.DEFAULT_PAGE_SIZE"
                [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
                [showFirstLastButtons]="true">
              </mat-paginator>
            </div>
          </div>
      </div>
  </div>
</div>