import { Component, HostListener, Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import * as ChatModels from '../models/chat.models';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public receivedEvent$: EventEmitter<ChatModels.ReceivedChatMessage>;
  public addNotification$: EventEmitter<number>;

  constructor(private http: HttpClient) {
    this.receivedEvent$ = new EventEmitter();
    this.addNotification$ = new EventEmitter();
  }

  public onReceivedChatMessage(message: ChatModels.ReceivedChatMessage): void {
    this.receivedEvent$.emit(message);
  }

  public onAddNotifiction(userID: number): void {
    this.addNotification$.emit(userID);
  }
}