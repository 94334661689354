<!-- Stats -->
<div class="row">
  <div class="col-md-12">
    <h2 class="ls-top-header">ניהול ממליצים</h2>
  </div>
</div>

<!-- Active Recommenders -->
<div class="panel-dark" style="border: unset !important;">
  <div class="panel-heading">
      <span class="panel-title">ממליצים פעילים</span>
  </div>
  <div class="panel-body" dir="rtl">
    <!-- Payments History Table. -->
    <mat-form-field style="text-align: right;width: 40%;">
      <mat-label>חיפוש</mat-label>
      <input matInput (keyup)="filterUsers($event)" placeholder="הזן שם משתמש / מספר פלאפון" #input>
    </mat-form-field>
    <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
      <table class="table-striped table-responsive-md" mat-table [dataSource]="recommendsData"
        matSortActive="started" matSortDisableClear #recommendsSort="matSort" matSort>
        <!-- FullName -->
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> משתמש </th>
          <td mat-cell *matCellDef="let user" class="td-details" style="padding-top: 5px;padding-bottom: 5px;">
            <p style="margin: 0 0 3px !important;">
              #{{ Formats.zeroPad(user.id, 4) }} - {{ user.fullName }} 
              <span [class]="Formats.formatScore(user.rating)"></span>
            </p>        
            <p style="margin: 0 0 3px !important;">
              אחוז זכייה: 
              <span *ngIf="user.successRate > 0">{{ user.successRate }}%</span>
              <span *ngIf="user.successRate <= 0">ללא</span>
            </p>
          </td>
        </ng-container>
        <!-- Phone -->
        <ng-container matColumnDef="mobile">
          <th class="table-header" mat-header-cell *matHeaderCellDef> פלאפון </th>
          <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let user">
            {{ Formats.ViewPhone(user.phone) }}
          </td>
        </ng-container>
        <!-- Plan -->
        <ng-container matColumnDef="plan">
          <th class="table-header" mat-header-cell *matHeaderCellDef> תוכנית </th>
          <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let user">
            {{ user.plan }}
          </td>
        </ng-container>
        <!-- Withdraw Status -->
        <ng-container matColumnDef="withdraw_status">
          <th class="table-header" mat-header-cell *matHeaderCellDef> התקדמות </th>
          <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let user">
            <mat-progress-bar [class]="Formats.progressBarClass((user.drawAmount / Settings.MAX_DRAW_AMOUNT) * 100)" mode="determinate" [value]="(user.drawAmount / Settings.MAX_DRAW_AMOUNT) * 100" style="display: inline-block;"></mat-progress-bar>
            <span *ngIf="user.drawAmount < Settings.MAX_DRAW_AMOUNT" style="direction: rtl;margin-right: 5px;">
                &#8362;{{ Settings.MAX_DRAW_AMOUNT }} / &#8362;{{ user.drawAmount }}
            </span>
            <span *ngIf="user.drawAmount >= Settings.MAX_DRAW_AMOUNT" style="direction: rtl;margin-right: 5px;">
              &#8362;{{ user.drawAmount }}
          </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="recommendsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: recommendsColumns;"></tr>
      </table>
      <!-- Search not found. -->
      <div style="margin-right: 10px" *ngIf="recommendsBadSearch !== ''">
        <h4>אין נתונים התואמים את החיפוש "{{ recommendsBadSearch }}"</h4>
      </div>
      <!-- Not found items situation. -->
      <div style="margin-right: 10px" *ngIf="totalRecommends <= 0">
        <h4>אין ממליצים לתצוגה.</h4>
      </div>
      <!-- Paginator setup. -->
      <mat-paginator 
        #recommendsPaginator
        [length]="totalRecommends"
        [pageSize]="Settings.DEFAULT_PAGE_SIZE"
        [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </div>
</div>

<!-- Recommenders -->
<div class="panel-dark" style="border: unset !important;">
  <div class="panel-heading">
      <span class="panel-title">בקשות ממליצים</span>
  </div>
  <div class="panel-body" dir="rtl">
    <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
      <table class="table-striped table-responsive-md" mat-table [dataSource]="pendingRequestsData"
        matSortActive="started" matSortDisableClear #pendingRequestsSort="matSort" matSort>
        <!-- FullName -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> ממליץ </th>
          <td mat-cell *matCellDef="let pt" class="td-details" style="padding-top: 5px;padding-bottom: 5px;">
            <p style="margin: 0 0 3px !important;">
              #{{ Formats.zeroPad(pt.userID, 4) }} - {{ pt.userName }}
            </p>        
          </td>
        </ng-container>
        <!-- Created -->
        <ng-container matColumnDef="created">
          <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך הבקשה</th>
          <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let pt">
            {{ this.datePipe.transform(pt.created, 'HH:mm - dd/MM/yyyy') }}
          </td>
        </ng-container>
        <!-- Amount -->
        <ng-container matColumnDef="amount">
          <th class="table-header" mat-header-cell *matHeaderCellDef> סכום </th>
          <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let pt">
            &#8362;{{ pt.amount }}
          </td>
        </ng-container>
        <!-- Status -->
        <ng-container matColumnDef="status">
          <th class="table-header" mat-header-cell *matHeaderCellDef> סטטוס </th>
          <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let pt">
            <span class="badge {{ Formats.DrawStatusText[pt.status].class }}">{{ Formats.DrawStatusText[pt.status].text }}</span>
          </td>
        </ng-container>
        <!-- Options -->
        <ng-container matColumnDef="options">
          <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
          <td mat-cell *matCellDef="let pt">
            <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #menu="matMenu" dir="rtl">
              <button class="option-btn" (click)="onApproveDrawDialogShow(pt.id, pt.userName, pt.amount)" mat-menu-item>
                <i class="fa fa-check" style="font-size: 16px;color:seagreen"></i>&nbsp;&nbsp;
                אישור העברה
              </button>
              <button class="option-btn" (click)="onDeclineDrawDialogShow(pt.id, pt.userName, pt.amount)" mat-menu-item>
                <i class="fa fa-times" style="font-size: 16px;color:#901d28"></i>&nbsp;&nbsp;
                דחיית בקשה
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="pendingRequestsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: pendingRequestsColumns;"></tr>
      </table>
      <!-- Not found items situation. -->
      <div style="margin-right: 10px" *ngIf="totalPendingRequests <= 0">
        <h4>אין בקשות ממליצים לתצוגה.</h4>
      </div>
      <!-- Paginator setup. -->
      <mat-paginator 
        #pendingRequestsPaginator
        [length]="totalPendingRequests"
        [pageSize]="Settings.DEFAULT_PAGE_SIZE"
        [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </div>
</div>

<!-- Requests History  -->
<div class="panel-dark" style="border: unset !important;">
  <div class="panel-heading">
      <span class="panel-title">היסטוריית בקשות</span>
  </div>
  <div class="panel-body" dir="rtl">
    <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
      <table class="table-striped table-responsive-md" mat-table [dataSource]="reuqestsHistoryData"
        matSortActive="started" matSortDisableClear #requestsHistorySort="matSort" matSort>
        <!-- FullName -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> ממליץ </th>
          <td mat-cell *matCellDef="let pt" class="td-details" style="padding-top: 5px;padding-bottom: 5px;">
            <p style="margin: 0 0 3px !important;">
              #{{ Formats.zeroPad(pt.userID, 4) }} - {{ pt.userName }}
            </p>        
          </td>
        </ng-container>
        <!-- Created -->
        <ng-container matColumnDef="created">
          <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך הבקשה</th>
          <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let pt">
            {{ this.datePipe.transform(pt.created, 'HH:mm - dd/MM/yyyy') }}
          </td>
        </ng-container>
        <!-- Amount -->
        <ng-container matColumnDef="amount">
          <th class="table-header" mat-header-cell *matHeaderCellDef> סכום </th>
          <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let pt">
            &#8362;{{ pt.amount }}
          </td>
        </ng-container>
        <!-- Status -->
        <ng-container matColumnDef="status">
          <th class="table-header" mat-header-cell *matHeaderCellDef> סטטוס </th>
          <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let pt">
            <span class="badge {{ Formats.DrawStatusText[pt.status].class }}">{{ Formats.DrawStatusText[pt.status].text }}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="requestsHistoryColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: requestsHistoryColumns;"></tr>
      </table>
      <!-- Not found items situation. -->
      <div style="margin-right: 10px" *ngIf="totalRequestsHistory <= 0">
        <h4>אין בקשות לתצוגה.</h4>
      </div>
      <!-- Paginator setup. -->
      <mat-paginator 
        #requestsHistoryPaginator
        [length]="totalRequestsHistory"
        [pageSize]="Settings.DEFAULT_PAGE_SIZE"
        [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
  </div>
</div>