<div class="row">
  <div class="col-md-12">
    <h2 class="ls-top-header">ניהול רכישות</h2>
  </div>
</div>

<!-- Pending Payments. -->
<div class="row home-row-top">
  <div class="col-md-12">
      <div class="panel-dark" style="border: unset !important;">
          <div class="panel-heading">
            <span class="panel-title">רכישות ממתינות</span>
          </div>
          <div class="panel-body" dir="rtl">
            <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
              <table class="table-striped table-responsive-md" mat-table [dataSource]="pendingPaymentsData"
                matSortActive="started" matSortDisableClear matSortDirection="desc" #pendingPaymentsSort="matSort" matSort>
                <!-- Title -->
                <ng-container matColumnDef="desc">
                  <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> תיאור הפריט</th>
                  <td mat-cell *matCellDef="let payment" class="td-details">
                    <div style="padding-right: 15px;white-space: nowrap;">
                      {{ payment.description }}
                    </div>
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="user">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> משתמש </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    {{ payment.userName }}
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="amount">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> מחיר </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    &#8362;{{ payment.amount }}
                  </td>
                </ng-container>
                <!-- Time -->
                <ng-container matColumnDef="created">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    {{ this.datePipe.transform(payment.created, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Type -->
                <ng-container matColumnDef="type">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> אמצעי תשלום </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    <span *ngIf="payment.type === PaymentTypes.CC">
                      כרטיס המסתיים ב {{ payment.lastDigits }}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="options">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let reco">
                    
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="pendingPaymentsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: pendingPaymentsColumns;"></tr>
              </table>
              <!-- Not found items situation. -->
              <div style="margin-right: 10px" *ngIf="pendingPaymentsLength <= 0">
                <h4>אין רכישות בהמתנה.</h4>
              </div>
              <!-- Paginator setup. -->
              <mat-paginator 
                #pendingPaymentsPaginator
                [length]="pendingPaymentsLength"
                [pageSize]="Settings.DEFAULT_PAGE_SIZE"
                [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
                [showFirstLastButtons]="true">
              </mat-paginator>
            </div>
          </div>
      </div>
  </div>
</div>

<!-- Payments History -->
<div class="row home-row-top">
  <div class="col-md-12">
      <div class="panel-dark" style="border: unset !important;">
          <div class="panel-heading">
              <span class="panel-title">היסטוריית רכישות</span>
          </div>
          <div class="panel-body" dir="rtl">
            <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
              <table class="table-striped table-responsive-md" mat-table [dataSource]="paymentsHistoryData"
                matSortActive="started" matSortDisableClear matSortDirection="desc" #paymentsHistorySort="matSort" matSort>
                <!-- Title -->
                <ng-container matColumnDef="desc">
                  <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> תיאור הפריט</th>
                  <td mat-cell *matCellDef="let payment" class="td-details">
                    <div style="padding-right: 15px;white-space: nowrap;">
                      {{ payment.description }}

                      <a *ngIf="payment.reco !== null" href="{{ Settings.WEBISTE_URL }}/reco/{{ payment.reco.id }}" target="_blank">
                        {{ payment.reco.title }}
                      </a>
                    </div>
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="user">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> משתמש </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    #{{ Formats.zeroPad(payment.userID, 4) }} -  {{ payment.userName }}
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="amount">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> מחיר </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    &#8362;{{ payment.amount }}
                  </td>
                </ng-container>
                <!-- Time -->
                <ng-container matColumnDef="created">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    {{ this.datePipe.transform(payment.created, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Type -->
                <ng-container matColumnDef="type">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> אמצעי תשלום </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    <span *ngIf="payment.type === PaymentTypes.CC">
                      אשראי המסתיים ב- {{ payment.lastDigits }}
                    </span>
                  </td>
                </ng-container>
                <!-- Status -->
                <ng-container matColumnDef="status">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> סטטוס </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    <span class="badge {{ Formats.PaymentStatusText[payment.status].class }}">
                      {{ Formats.PaymentStatusText[payment.status].text }}
                    </span>
                  </td>
                </ng-container>
                <ng-container matColumnDef="options">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    <a *ngIf="payment.receiptURL !== null" target="_blank" [href]="payment.receiptURL">צפייה בקבלה</a>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="paymentsHistoryColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: paymentsHistoryColumns;"></tr>
              </table>
              <!-- Not found items situation. -->
              <div style="margin-right: 10px" *ngIf="paymentsHistoryLength <= 0">
                <h4>אין רכישות לתצוגה.</h4>
              </div>
              <!-- Paginator setup. -->
              <mat-paginator 
                #paymentsHistoryPaginator
                [length]="paymentsHistoryLength"
                [pageSize]="Settings.DEFAULT_PAGE_SIZE"
                [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
                [showFirstLastButtons]="true">
              </mat-paginator>
            </div>
          </div>
      </div>
  </div>
</div>

<!-- Payments History -->
<div class="row home-row-top">
  <div class="col-md-12">
      <div class="panel-dark" style="border: unset !important;">
          <div class="panel-heading">
              <span class="panel-title">סירובי רכישות</span>
          </div>
          <div class="panel-body" dir="rtl">
            <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
              <table class="table-striped table-responsive-md" mat-table [dataSource]="paymentsFailedData"
                matSortActive="started" matSortDisableClear matSortDirection="desc" #paymentsFailedSort="matSort" matSort>
                <!-- Title -->
                <ng-container matColumnDef="desc">
                  <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> תיאור הפריט</th>
                  <td mat-cell *matCellDef="let payment" class="td-details">
                    <div style="padding-right: 15px;white-space: nowrap;">
                      {{ payment.description }}

                      <a *ngIf="payment.reco !== null" href="{{ Settings.WEBISTE_URL }}/reco/{{ payment.reco.id }}" target="_blank">
                        {{ payment.reco.title }}
                      </a>
                    </div>
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="user">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> משתמש </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    #{{ Formats.zeroPad(payment.userID, 4) }} -  {{ payment.userName }}
                  </td>
                </ng-container>
                <!-- Creator -->
                <ng-container matColumnDef="amount">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> מחיר </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    &#8362;{{ payment.amount }}
                  </td>
                </ng-container>
                <!-- Time -->
                <ng-container matColumnDef="created">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> תאריך </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    {{ this.datePipe.transform(payment.created, 'HH:mm - dd/MM/yyyy') }}
                  </td>
                </ng-container>
                <!-- Type -->
                <ng-container matColumnDef="type">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> אמצעי תשלום </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    <span *ngIf="payment.type === PaymentTypes.CC">
                      אשראי המסתיים ב- {{ payment.lastDigits }}
                    </span>
                  </td>
                </ng-container>
                <!-- Status -->
                <ng-container matColumnDef="status">
                  <th class="table-header" mat-header-cell *matHeaderCellDef> סטטוס </th>
                  <td class="td-value" style="text-align: right;" mat-cell *matCellDef="let payment">
                    <span class="badge status-closed">
                      סירוב
                    </span>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="paymentsFailedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: paymentsFailedColumns;"></tr>
              </table>
              <!-- Not found items situation. -->
              <div style="margin-right: 10px" *ngIf="paymentsFailedLength <= 0">
                <h4>אין סירובי רכישות לתצוגה.</h4>
              </div>
              <!-- Paginator setup. -->
              <mat-paginator 
                #paymentsFailedPaginator
                [length]="paymentsFailedLength"
                [pageSize]="Settings.DEFAULT_PAGE_SIZE"
                [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
                [showFirstLastButtons]="true">
              </mat-paginator>
            </div>
          </div>
      </div>
  </div>
</div>