
export interface Topic {

  id: number;
  name: string;
};

export interface Game {

  id: number;
  name: string;
  iconSrc?: string;
}

export interface Group {
  id: number;
  name: string;
  game: Game,
  imageSrc: string;
}

export interface GameGroup {
  games: Game[];
  groups: Group[];
}

export interface GamesGroup {
  games: Game[];
  group: Group;
}

export enum AdvDisplayTypes
{
    NONE = 0,
    ALONG = 1,
    ACROSS = 2
};

export interface Adv {
  title: string;
  link: string;
  image: string;
  clicks: number;
  display: AdvDisplayTypes;
}

export interface DataTable<T> {
  items: T;
  totalItems: number;
}
