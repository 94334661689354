<!--=================-- [ Approve Withdraw Dialog ] --=================-->
<div class="dialog-inner-content">
  <h1 class="dialog-title" mat-dialog-title>
    אישור בקשת משיכה
  </h1>
  <div mat-dialog-content style="padding-bottom: 10px;">
    <div style="margin-top: 2px;margin-bottom: 3px;">
        לאשר את הבקשה של <b>{{ drawInfo.user }}</b> לקבלת <b>{{ drawInfo.amount }}</b> ש"ח?
    </div>
  </div>
</div>
<div mat-dialog-actions style="text-align:left">
  <button mat-button class="btn-close" (click)="onCloseDialog()">יציאה</button>
  <button (click)="onApproveDrawSubmitted()" class="dialog-btn btn-approve" mat-button>
    אישור
  </button>
</div>