<!--=================-- [ Create Game ] --=================-->
<form [formGroup]="createAdvForm">
  <div class="dialog-inner-content">
    <!-- Dialog Title -->
    <h1 class="dialog-title" mat-dialog-title>
      פרסומת חדשה
    </h1>
    <div mat-dialog-content>
      <!-- Image Uploaded -->
      <div style="text-align: center;margin-bottom: 6px;">
        <img *ngIf="uploadImageHash !== ''" [src]="uploadImageHash" style="width: 24px; height: 24px">
      </div>
      <!-- Upload Image -->
      <div style="text-align: center;">
        <app-material-file-upload (change)="onFileChange($event)" text="העלאת תמונה" (complete)="onFileComplete($event)"></app-material-file-upload>
      </div>
      <!-- Title -->
      <mat-form-field class="dialog-form-field" dir="rtl">
        <mat-label for="title">כותרת</mat-label>
        <input [ngClass]="{ 'is-invalid': submitted && fc.title.errors }" formControlName="title" matInput />
        <mat-error *ngIf="fc.title.hasError('required')">
          <strong>הזן כותרת לפרסומת.</strong>
        </mat-error>
      </mat-form-field>
      <!-- Display -->
      <mat-form-field class="dialog-form-field" dir="rtl">
        <mat-label for="name">תצוגה</mat-label>
        
        <mat-select [ngClass]="{ 'is-invalid': submitted && fc.display.errors }" formControlName="display">
          <mat-option *ngFor="let item of Formats.AdvDisplayTypes | keyvalue" [value]="item.key">
            &nbsp;{{ item.value }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="fc.display.hasError('required')">
          <strong>בחר סוג תצוגה.</strong>
        </mat-error>
      </mat-form-field>
      <!-- Link -->
      <mat-form-field class="dialog-form-field" dir="rtl">
        <mat-label for="link">קישור</mat-label>
        <input [ngClass]="{ 'is-invalid': submitted && fc.link.errors }" formControlName="link" matInput />
        <mat-error *ngIf="fc.link.hasError('required')">
          <strong>הזן קישור לפרסומת.</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- Dialog Actions -->
  <div mat-dialog-actions style="text-align:left">
    <!-- Close -->
    <button mat-button class="btn-close" (click)="onCloseDialog()">יציאה</button>
    <!-- Submit -->
    <button (click)="onCreateAdvSubmitted()" class="dialog-btn btn-continue" mat-button>
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      המשך
    </button>
  </div>
</form>