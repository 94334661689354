<!--=================-- [ Delete Group ] --=================-->
<div class="dialog-inner-content">
  <h1 class="dialog-title" mat-dialog-title>
    מחיקת קבוצה
  </h1>
  <div mat-dialog-content>
    למחוק את הקבוצה <img [src]="groupDeleted.imageSrc" style="width: 16px; height: 16px">&nbsp;<b>{{ groupDeleted.name }}</b>?
  </div>
</div>
<div mat-dialog-actions style="text-align:left">
  <button mat-button class="btn-close" (click)="onCloseDialog()">יציאה</button>
  <button (click)="onDeleteGroupSubmitted()" class="dialog-btn btn-delete" mat-button>
    מחיקה
  </button>
</div>