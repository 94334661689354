<!--=================-- [ Ban User ] --=================-->
<div class="dialog-inner-content">
  <h1 class="dialog-title" mat-dialog-title>
    הרחקת משתמש #{{ Formats.zeroPad(bannedUser.id, 4) }}
  </h1>
  <div mat-dialog-content>
    להרחיק את המשתמש של <b>{{ bannedUser.fullName }}</b>?
  </div>
</div>
<div mat-dialog-actions style="text-align:left">
  <button mat-button class="btn-close" (click)="onCloseDialog()">יציאה</button>
  <button (click)="onBanUserSubmitted()" class="dialog-btn btn-delete" mat-button>
    הרחקה
  </button>
</div>