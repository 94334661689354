<div class="row">
  <div class="col-md-12">
    <h2 class="ls-top-header">ניהול פרסומות</h2>
    <button class="btn-submit btn-default" (click)="onCreateAdvDialogShow()" style="margin-right: 20px;" mat-flat-button>
      <i class="fa fa-plus"></i> פרסומת חדשה
    </button>
  </div>
</div>
<br>

<!-- Games -->
<div class="panel-dark" style="border: unset !important;">
  <div class="panel-heading">
      <span class="panel-title">פרסומות</span>
  </div>
  <div class="panel-body" dir="rtl">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
          <table class="table-striped table-responsive-md" mat-table [dataSource]="advsData"
            matSortActive="started" matSortDisableClear matSortDirection="desc" #advsSort="matSort" matSort>
            <!-- Title -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> כותרת </th>
              <td mat-cell *matCellDef="let adv" class="td-details">
                {{ adv.title }}
              </td>
            </ng-container>
            <!-- Image -->
            <ng-container matColumnDef="image">
              <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> תמונה </th>
              <td mat-cell *matCellDef="let adv" class="td-details">
                <img *ngIf="adv.display === DisplayTypes.ACROSS" [src]="adv.imageSrc" width="600" />
                <img *ngIf="adv.display !== DisplayTypes.ACROSS" [src]="adv.imageSrc" height="200" />
              </td>
            </ng-container>
            <!-- Display -->
            <ng-container matColumnDef="display">
              <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> תצוגה </th>
              <td mat-cell *matCellDef="let adv" class="td-details">
                {{ Formats.AdvDisplayTypes[adv.display] }}
              </td>
            </ng-container>
            <!-- Clicks -->
            <ng-container matColumnDef="clicks">
              <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> כניסות </th>
              <td mat-cell *matCellDef="let adv" class="td-details">
                <span *ngIf="adv.clicks === 0">ללא</span>
                <span *ngIf="adv.clicks > 0">{{ adv.clicks }}</span>
              </td>
            </ng-container>
            <!-- Options -->
            <ng-container matColumnDef="options">
              <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
              <td mat-cell *matCellDef="let group">
                <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #menu="matMenu">
                  <button class="reco-option-btn" (click)="onEditGroupDialog(group)" mat-menu-item>עריכה</button>
                  <button class="reco-option-btn delete-btn" (click)="onDeleteGroupDialog(group)" mat-menu-item>מחיקה</button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="advsColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: advsColumns;"></tr>

          </table>
          <!-- Not found items situation. -->
          <div style="margin-right: 10px" *ngIf="advsTotal <= 0">
            <h4>אין פרסומות לתצוגה.</h4>
          </div>
        <!-- Paginator setup. -->
        <mat-paginator 
          #advsPaginator
          [length]="advsTotal"
          [pageSize]="10"
          [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
          [showFirstLastButtons]="true"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>