// Angular libraries
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// Global
import * as Settings from '../global/settings';

// Shared
import { StatusCodes } from '../../../../shared/status.codes';

// Utilities
import * as Formats from '../utils/formats';

// Models
import * as MainModels from '../models/main.models';
import * as RecoModels from '../models/reco.models';
import * as GlobalModels from '../models/global.models';

// Systems
import { Global } from '../systems/global';
import { Notifications } from '../systems/notifications';
import { Users } from '../systems/users';
import { Recos } from '../systems/recos';

// Componenets
import { CancelRecoComponent } from '../recos/cancel-reco/cancel-reco.component';
import { MarkIncorrectRecoComponent } from '../recos/mark-incorrect-reco/mark-incorrect-reco.component';
import { MarkCorrectRecoComponent } from '../recos/mark-correct-reco/mark-correct-reco.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {

  // Implements
  Settings = Settings;
  Formats = Formats;

  // Systems
  private global: Global;
  private users: Users;
  private notifications: Notifications;
  private recos: Recos;

  // Data
  mainData: MainModels.MainData;
  globalData: GlobalModels.GameGroup;

  // Flags
  private isGlobalLoaded: boolean = false;

  // Recommendations
  recosLoading = true;
  openRecosData: RecoModels.Reco[] = [];
  recoColumns: string[] = [ 
    'title',
    'creator',
    'result', 
    'datetime', 
    'options' 
  ];
  recosTotalItems = Settings.DEFAULT_LOADED_VALUE;

  // Paginators
  @ViewChild('recosPaginator') recosPaginator: MatPaginator;
  
  // Sorts
  @ViewChild('recosSort') recosSort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private dialog: MatDialog, 
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private http: HttpClient) {

    this.users = new Users(this.http);
    this.recos = new Recos(this.http);
    this.global = new Global(this.http, false);
    this.notifications = new Notifications(this.snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);
    
    try {
      this.users.GetMainData().pipe(
        map((data: MainModels.MainData) => { 
          this.mainData = data 
        }),
        catchError(() => { 
          return observableOf([]); 
        })
      ).subscribe(() => {  
        
      });
    } catch(err) { } 
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // =================-- [ Open Recommendations ] --=================
    Formats.translatePaginator(this.recosPaginator);

    merge(this.recosSort.sortChange, this.recosPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.recosLoading = true;
          return this.recos!.GetRecos(RecoModels.StatusTypes.OPEN, this.recosPaginator.pageIndex + 1, this.recosPaginator.pageSize);
        }),
        map(data => {
          this.recosLoading = false;
          this.recosTotalItems = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          this.recosLoading = false;
          this.recosTotalItems = 0;
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.openRecosData = data;
      }
    );
  }

  navigateToEditReco(id: number): void {
    window.location.href = Settings.WEBISTE_URL + "/edit-reco/" + id;
  }

  onMarkIncorrectDialogShow(reco: RecoModels.Reco): void {
    const dialogRef = this.dialog.open(MarkIncorrectRecoComponent, {
      width: '400px',
      data: reco
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("closed");
      if(typeof(result) == 'object') {
        this.ngAfterViewInit();
      }
    });
  }

  onMarkCorrectDialogShow(reco: RecoModels.Reco): void {
    const dialogRef = this.dialog.open(MarkCorrectRecoComponent, {
      width: '400px',
      data: reco
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        this.ngAfterViewInit();
      }
    });
  }

  onCancelRecoDialogShow(reco: RecoModels.Reco): void {
    const dialogRef = this.dialog.open(CancelRecoComponent, {
      width: '400px',
      data: reco
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        this.ngAfterViewInit();
      }
    });
  }

}
