<!--=================-- [ Create Group ] --=================-->
<form [formGroup]="createGroupForm">
  <div class="dialog-inner-content">
    <!-- Dialog Title -->
    <h1 class="dialog-title" mat-dialog-title>
      קבוצה חדשה
    </h1>
    <div mat-dialog-content>
      <!-- Image Uploaded -->
      <div style="text-align: center;margin-bottom: 6px;">
        <img *ngIf="uploadImageHash !== ''" [src]="uploadImageHash" style="width: 24px; height: 24px">
      </div>
      <!-- Upload Image -->
      <div style="text-align: center;">
        <app-material-file-upload (change)="onFileChange($event)" text="העלאת סמל" (complete)="onFileComplete($event)"></app-material-file-upload>
      </div>
      <!-- Name -->
      <mat-form-field class="dialog-form-field" dir="rtl">
        <mat-label for="name">שם הקבוצה</mat-label>
        <input [ngClass]="{ 'is-invalid': submitted && fc.name.errors }" formControlName="name" matInput />
        <mat-error *ngIf="fc.name.hasError('required')">
          <strong>הזן את שם הקבוצה.</strong>
        </mat-error>
      </mat-form-field>
      <!-- Game -->
      <mat-form-field class="dialog-form-field" dir="rtl">
        <mat-label for="name">סוג משחק</mat-label>
        
        <mat-select [ngClass]="{ 'is-invalid': submitted && fc.game.errors }" formControlName="game">
          <mat-option *ngFor="let game of gamesOptions" [value]="game.id">
            <img [src]="game.iconSrc" style="width: 16px; height: 16px">&nbsp;{{ game.name }}
          </mat-option>
        </mat-select>

        <mat-error *ngIf="fc.game.hasError('required')">
          <strong>בחר סוג משחק.</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- Dialog Actions -->
  <div mat-dialog-actions style="text-align:left">
    <!-- Close -->
    <button mat-button class="btn-close" (click)="onCloseDialog()">יציאה</button>
    <!-- Submit -->
    <button (click)="onCreateGroupSubmitted()" class="dialog-btn btn-continue" mat-button>
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      המשך
    </button>
  </div>
</form>