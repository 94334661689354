// Angular libraries
import { Component, OnInit, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// Utils
import * as Settings from '../global/settings';
import * as Formats from '../utils/formats';

// RxJS
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap, } from 'rxjs/operators';

// Models
import * as ChatModels from '../models/chat.models';

// Systems
import { Users } from '../systems/users';
import { ChatService } from '../services/chat.service';
import { Notifications } from '../systems/notifications';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit {

  Formats = Formats;

  chatMsg = new FormControl('');
  chatUsers: ChatModels.ViewAdminClassModel[] = [];
  currentConv: ChatModels.ConvsViewModel;
  isLoadingUsers: boolean = false;
  isLoadingChats: boolean = false;
  openChatWith: number = 0;

  private users: Users;
  private notifications: Notifications;

  @ViewChild('chatlogs') chatlogs: ElementRef;
  @ViewChild('contacts') contacts: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private http: HttpClient,
    private dialog: MatDialog,
    private chatService: ChatService,
    private cdRef: ChangeDetectorRef) {

      this.users = new Users(this.http);
      this.notifications = new Notifications(this.snackBar, Settings.NOTIF_CLOSE_TEXT, 1000);

      this.chatService.receivedEvent$.subscribe((message) => this.onChatMessageReceived(message));

      var fetchUsersChats = this.users.GetSupportConversations(0);

      if(fetchUsersChats !== undefined) {
        fetchUsersChats.pipe(
          startWith(),
          map((data: ChatModels.ViewAdminClassModel[]) => {
            return data;
          }),
          catchError(() => {
            this.isLoadingUsers = false;
            return observableOf([]);
          })
        ).subscribe(
          (data) => {
            var convs = data as ChatModels.ViewAdminClassModel[];
            if(convs !== undefined) {
              this.chatUsers = convs;
              this.openChatWith = this.chatUsers[0].userID;
              this.updateChatWith(this.openChatWith);
            }     
          }
        );

      }
    }

  ngOnInit(): void {
  }

  private scrollToBottom() {
    this.chatlogs.nativeElement.scrollTop = this.chatlogs.nativeElement.scrollHeight - this.chatlogs.nativeElement.clientHeight;
  }

  updateChatWith(id: number): void {
    var fetchConversation = this.users.GetSupportConversation(id, 0);
    if(fetchConversation !== undefined) {
      fetchConversation.pipe(
        startWith(),
        map((data: ChatModels.ConvsViewModel) => {
          return data;
        }),
        catchError(() => {
          return observableOf([]);
        }),
      ).subscribe(
        (data) => {
          if(data) {
            this.currentConv = data as ChatModels.ConvsViewModel;
            if(this.currentConv.chats !== undefined) {
              this.openChatWith = id;
              this.currentConv.chats = this.currentConv.chats.reverse();
              var idx = this.chatUsers.findIndex(x => x.userID === id);
              this.chatUsers[idx].unReadMsgs = 0;
              //this.cdRef.detectChanges();
              setTimeout(() => {
                this.scrollToBottom();
              }, 100);
            }
          }
        }
      );
    }
  }

  // on chat message recevied.
  private onChatMessageReceived(message: ChatModels.ReceivedChatMessage) {
    console.log(message);
    // if chat is open with sender update the new message.
    if(this.openChatWith == message.sender) {
      this.currentConv.chats.push({
        id: 0,
        message: message.message,
        sender: false,
        created: new Date()
      });
      this.cdRef.detectChanges();
      this.scrollToBottom();
    }
    else {
      var idx = this.chatUsers.findIndex(x => x.userID === message.sender);
      this.chatUsers[idx].unReadMsgs += 1;
      this.cdRef.detectChanges();
    }
  }

  @HostListener('window:scroll', ['$event']) onScrollChatsEvent(e: Event) {

    var scrollTop = (e.target as Element).scrollTop;
    
    if(this.chatlogs.nativeElement.scrollTop === 0 && !this.isLoadingChats) {

      var conv = this.users.GetSupportConversation(this.openChatWith, this.currentConv.chats[0].id);
      if(conv !== undefined) {
        conv.pipe(
          startWith(),
          map((data: ChatModels.ConvsViewModel) => {
            return data;
          }),
          catchError(() => {
            this.isLoadingChats = false;
            return observableOf([]);
          })
        ).subscribe(
          (data) => {
            var convs = data as ChatModels.ConvsViewModel;
            if(convs.chats !== undefined && convs.chats.length > 0) {
              this.currentConv.chats.unshift.apply(this.currentConv.chats, convs.chats.reverse());
              this.isLoadingChats = false;
              this.cdRef.detectChanges();
              this.scrollToBottom();
            }        
          }
        );
      }
    }
  }


  @HostListener('window:scroll', ['$event']) onScrollContactsEvent(e: Event) {

    var contactsWindow = this.contacts.nativeElement;
    
    var isBottom = contactsWindow.scrollHeight - contactsWindow.scrollTop === contactsWindow.clientHeight;
    
    if(isBottom && !this.isLoadingUsers) {
      console.log("trying to load users...");
    }
  } 

  sendChatMessage(): void {
    var chatMsg = this.chatMsg.value;
    if(chatMsg === null || chatMsg.match(/^ *$/) !== null) {
      this.notifications.Error("אין אפשרות לשלוח הודעה ריקה.");
    }
    else {
      this.users.SendChatMessage(this.openChatWith, chatMsg).subscribe(
        (data) => {
          this.currentConv.chats.push({
            id: 0,
            message: chatMsg,
            sender: true,
            created: new Date()
          });
          this.chatMsg.reset();
          this.cdRef.detectChanges();
          this.scrollToBottom();
        },
        (err) => {

        }
      )
    }
  }

}
