// Angular libraries
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// Global
import * as Settings from '../global/settings';

// Shared
import { StatusCodes } from '../../../../shared/status.codes';

// Utilities
import * as Formats from '../utils/formats';

// Models
import * as MainModels from '../models/main.models';
import * as RecoModels from '../models/reco.models';
import * as GlobalModels from '../models/global.models';

// Systems
import { Global } from '../systems/global';
import { Notifications } from '../systems/notifications';
import { Recos } from '../systems/recos';

// Componenets
import { CancelRecoComponent } from './cancel-reco/cancel-reco.component';
import { MarkIncorrectRecoComponent } from './mark-incorrect-reco/mark-incorrect-reco.component';
import { MarkCorrectRecoComponent } from './mark-correct-reco/mark-correct-reco.component';

@Component({
  selector: 'app-recos',
  templateUrl: './recos.component.html',
  styleUrls: ['./recos.component.scss']
})
export class RecosComponent implements OnInit, AfterViewInit {

   // Implements
   Settings = Settings;
   Formats = Formats;
   RecoStatusTypes = RecoModels.StatusTypes;
   RecoResultTypes = RecoModels.ResultTypes;
 
   // Systems
   private global: Global;
   private notifications: Notifications;
   private recos: Recos;
 
   // Data
   globalData: GlobalModels.GameGroup;
 
   // Flags
   private isGlobalLoaded: boolean = false;
 
  // Open Recos
  openRecosLoading = true;
  openRecosData: RecoModels.Reco[] = [];
  recoColumns: string[] = [ 
    'title',
    'creator',
    'result', 
    'datetime',
    'created', 
    'options' 
  ];
  openRecosTotalItems = Settings.DEFAULT_LOADED_VALUE;

  // Pending Recos
  pendingRecosLoading = true;
  pendingRecosData: RecoModels.Reco[] = [];
  pendingRecosTotal = Settings.DEFAULT_LOADED_VALUE;

  // All Recos
  recosLoading = true;
  recosData: RecoModels.Reco[] = [];
  recosTotal = Settings.DEFAULT_LOADED_VALUE;

  // Paginators
  @ViewChild('openRecosPaginator') openRecosPaginator: MatPaginator;
  @ViewChild('pendingRecosPaginator') pendingRecosPaginator: MatPaginator;
  @ViewChild('recosPaginator') recosPaginator: MatPaginator;
  
  // Sorts
  @ViewChild('openRecosSort') openRecosSort: MatSort;
  @ViewChild('pendingRecosSort') pendingRecosSort: MatSort;
  @ViewChild('recosSort') recosSort: MatSort;

   constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog, 
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private http: HttpClient) {

    this.recos = new Recos(this.http);
    this.global = new Global(this.http, false);
    this.notifications = new Notifications(this.snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);
  }
    

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    // Get relevant global types (Games & Groups).
    var getGlobalTypes = this.global.GetGroupsAndGames();
    if(getGlobalTypes !== undefined) {
      getGlobalTypes.pipe(map((data: GlobalModels.GameGroup) => { this.globalData = data; })).subscribe(
        () => {
          // =================-- [ Open Recommendations ] --=================
          Formats.translatePaginator(this.recosPaginator);

          merge(this.recosSort.sortChange, this.recosPaginator.page)
            .pipe(
              startWith({}),
              switchMap(() => {
                this.openRecosLoading = true;
                return this.recos!.GetRecos(RecoModels.StatusTypes.OPEN, this.recosPaginator.pageIndex + 1, this.recosPaginator.pageSize);
              }),
              map(data => {
                this.openRecosLoading = false;
                this.openRecosTotalItems = data.totalItems;
                return data.items;
              }),
              catchError(() => {
                this.openRecosLoading = false;
                this.openRecosTotalItems = 0;
                return observableOf([]);
              })
          ).subscribe(
            (data) => { 
              this.openRecosData = data;
            }
          );
          // =================-- [ Pending Recommendations ] --=================
          Formats.translatePaginator(this.pendingRecosPaginator);

          merge(this.pendingRecosSort.sortChange, this.pendingRecosPaginator.page)
            .pipe(
              startWith({}),
              switchMap(() => {
                this.pendingRecosLoading = true;
                return this.recos!.GetRecos(RecoModels.StatusTypes.PENDING, this.pendingRecosPaginator.pageIndex + 1, this.pendingRecosPaginator.pageSize);
              }),
              map(data => {

                this.pendingRecosLoading = false;
                this.pendingRecosTotal = data.totalItems;
                return data.items;
              }),
              catchError(() => {
                this.pendingRecosLoading = false;
                this.pendingRecosTotal = 0;
                return observableOf([]);
              })
          ).subscribe(
            (data) => { 
              this.pendingRecosData = data;
            }
          );
          // =================-- [ All Recommendations ] --=================
          Formats.translatePaginator(this.recosPaginator);

          merge(this.pendingRecosSort.sortChange, this.recosPaginator.page)
            .pipe(
              startWith({}),
              switchMap(() => {
                this.recosLoading = true;
                return this.recos!.GetRecos(RecoModels.StatusTypes.NONE, this.recosPaginator.pageIndex + 1, this.recosPaginator.pageSize);
              }),
              map(data => {

                this.recosLoading = false;
                this.recosTotal = data.totalItems;
                return data.items;
              }),
              catchError(() => {
                this.recosLoading = false;
                this.recosTotal = 0;
                return observableOf([]);
              })
          ).subscribe(
            (data) => { 
              this.recosData = data;
            }
          );
        }
      );
    }
  }

  navigateToEditReco(id: number): void {
    window.location.href = Settings.WEBISTE_URL + "/edit-reco/" + id;
  }

  onMarkIncorrectDialogShow(reco: RecoModels.Reco): void {
    const dialogRef = this.dialog.open(MarkIncorrectRecoComponent, {
      width: '400px',
      data: reco
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("closed");
      if(typeof(result) == 'object') {
        this.ngAfterViewInit();
      }
    });
  }

  onMarkCorrectDialogShow(reco: RecoModels.Reco): void {
    const dialogRef = this.dialog.open(MarkCorrectRecoComponent, {
      width: '400px',
      data: reco
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        this.ngAfterViewInit();
      }
    });
  }

  onCancelRecoDialogShow(reco: RecoModels.Reco): void {
    const dialogRef = this.dialog.open(CancelRecoComponent, {
      width: '400px',
      data: reco
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        this.ngAfterViewInit();
      }
    });
  }

}
