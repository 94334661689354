// Angular libraries
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Global
import * as Settings from '../../../global/settings';
import { ServerRoutes } from '../../.././global/routes';

// Shared
import { StatusCodes } from '../../../../../../shared/status.codes';

// Models
import * as GlobalModels from '../../../models/global.models';

// Systems
import { Notifications } from '../../../systems/notifications';
import { Global } from '../../../systems/global';

// Notifications
const editGameNotifications = {
  [StatusCodes.INTERNAL]: "‫אופס! נתקלנו בשגיאה. אנא נסה שוב בהמשך.‬‎",
  [StatusCodes.INVALID_TOKEN]: "הזדהה בכדי לבצע פעולה זו.",
  [StatusCodes.OBJECT_NOT_FOUND]: "משחק לא נמצא.",
  [StatusCodes.INVALID_TYPE_NAME]: "שם המשחק שגוי.",
  [StatusCodes.TYPE_ALREADY_EXISTS]: "שם המשחק שהזנת כבר קיים.",
  [StatusCodes.OK]: "פרטי המשחק נשמרו בהצלחה!"
};

@Component({
  selector: 'app-edit-game',
  templateUrl: './edit-game.component.html',
  styleUrls: ['../../dialogs.style.scss']
})
export class EditGameComponent implements OnInit {
  editGameForm: FormGroup;
  gameEdited: GlobalModels.Game;
  
  private notifications: Notifications;
  private global: Global;

  submitted = false;
  loading = false;

  uploadImageHash = "";

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditGameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GlobalModels.Game) { 
    
    this.gameEdited = {
      id: data.id,
      name: data.name,
      iconSrc: data.iconSrc
    };
    this.global = new Global(this.http, true);
    this.notifications = new Notifications(snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);

    this.editGameForm = this.formBuilder.group({
      name: [data.name, [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  get fc() {
    return this.editGameForm.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const fileName = event.target.files[0].name;

      var re = /(?:\.([^.]+))?$/;

      if(!Settings.ALLOWED_IMAGE_EXTENSIONS.includes(re.exec(fileName)[1])) {
        this.notifications.Error(editGameNotifications[StatusCodes.EXTENSION_NOT_ALLOWED]);
        return;
      }
    }
  }

  onFileComplete(fileHash: string): void {
    this.gameEdited.iconSrc = this.uploadImageHash = `${Settings.SERVER_URL}${ServerRoutes.VIEW_TEMP_IMAGE}/${fileHash}`;
  }


  onEditGameSubmitted(): void {
    this.submitted = true;

    if(this.editGameForm.invalid) {
      this.notifications.Error(editGameNotifications[StatusCodes.FIELDS_REQURIED]);
      return;
    }

    this.loading = true;

    let imageHash = "";
    if(this.uploadImageHash.length > 0)
      imageHash = this.uploadImageHash.slice(this.uploadImageHash.lastIndexOf("/") + 1);

    this.global.EditGame(this.gameEdited.id, this.fc.name.value, imageHash).subscribe(
      (data: GlobalModels.Game) => {
        this.notifications.Ok(editGameNotifications[StatusCodes.OK]);
        this.dialogRef.close({
          id: data.id,
          name: data.name,
          iconSrc: this.gameEdited.iconSrc
        });
      },
      (err) => {
        this.notifications.Error(err.error.error_code === undefined ? 
          editGameNotifications[StatusCodes.INTERNAL] : editGameNotifications[err.error.error_code]);
      }
    );
  }
  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
