import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-adv',
  templateUrl: './delete-adv.component.html',
  styleUrls: ['./delete-adv.component.scss']
})
export class DeleteAdvComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
