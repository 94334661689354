<body [ngClass]="[!isLogin ? 'login-screen' : '']">
  <div *ngIf="isLogin">
    <!-- Navigation Top Bar -->
    <nav class="navigation">
      <div class="container-fluid">
          <div class="header-logo">
              <a href="/Admin/Index" title="">
                  <h1>פאנל ניהול האתר</h1>
              </a>
          </div>
          <div class="top-navigation">
              <div class="menu-control hidden-xs">
                  <a href="javascript:void(0)">
                      <i class="fa fa-bars"></i>
                  </a>
              </div>
              <div class="search-box">
                  <ul>
                      <li class="dropdown">
                          <a class="dropdown-toggle" [href]="Settings.WEBISTE_URL">
                              חזרה לאתר הראשי
                          </a>
                      </li>
                  </ul>
              </div>
              <ul>
                  <li class="dropdown">
                      <a class="dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)">
                          <span class="fa fa-envelope-o"></span>
                          <span class="badge badge-red"></span>
                      </a>
                      <div class="dropdown-menu right email-notification">
                          <h4>Email</h4>
                          <ul class="email-top">
                              <li>
                                  <a href="javascript:void(0)">
                                      <div class="email-top-image">
                                          <img class="rounded" src="" alt="user image" />
                                      </div>
                                      <div class="email-top-content">
                                          John Doe <div>Sample Mail 1</div>
                                      </div>
                                  </a>
                              </li>
                              <li>
                                  <a href="javascript:void(0)">
                                      <div class="email-top-image">
                                          <img class="rounded" src="" alt="user image" />
                                      </div>
                                      <div class="email-top-content">
                                          John Doe <div>Sample Mail 2</div>
                                      </div>
                                  </a>
                              </li>
                              <li class="only-link">
                                  <a href="mail.html">View All</a>
                              </li>
                          </ul>
                      </div>
                  </li>
                  <li class="dropdown">
                      <a class="dropdown-toggle" href="/Admin/Chats">
                          <span class="fa fa-comment-o"></span>
                          <span class="badge badge-red"></span>
                      </a>
                      <div class="dropdown-menu right email-notification">
                          <h4>Email</h4>
                          <ul class="email-top">
                              <li>
                                  <a href="javascript:void(0)">
                                      <div class="email-top-image">
                                          <img class="rounded" src="" alt="user image" />
                                      </div>
                                      <div class="email-top-content">
                                          John Doe <div>Sample Mail 1</div>
                                      </div>
                                  </a>
                              </li>
                              <li>
                                  <a href="javascript:void(0)">
                                      <div class="email-top-image">
                                          <img class="rounded" src="" alt="user image" />
                                      </div>
                                      <div class="email-top-content">
                                          John Doe <div>Sample Mail 2</div>
                                      </div>
                                  </a>
                              </li>
                              <li>
                                  <a href="javascript:void(0)">
                                      <div class="email-top-image">
                                          <img class="rounded" src="" alt="user image" />
                                      </div>
                                      <div class="email-top-content">
                                          John Doe <div> Sample Mail 4</div>
                                      </div>
                                  </a>
                              </li>
                              <li class="only-link">
                                  <a href="mail.html">View All</a>
                              </li>
                          </ul>
                      </div>
                  </li>
                  <li>
                      <a class="right-sidebar-setting" href="/Admin/Settings">
                          <i class="fa fa-cogs"></i>
                      </a>
                  </li>
                  <li>
                      <a href="/Main/Logout">
                          <i class="fa fa-power-off"></i>
                      </a>
                  </li>
              </ul>
          </div>
      </div>
    </nav>
    <section id="main-container">
      <div class="phone-nav-box visible-xs">
          <a class="phone-logo" href="/Main/Index" title="">
              <h1>חזרה לאתר הראשי</h1>
          </a>
          <a class="phone-logo" href="/Admin/Index" title="">
              <h1>פאנל ניהול האתר</h1>
          </a>
          <a class="phone-nav-control" href="javascript:void(0)">
              <span class="fa fa-bars"></span>
          </a>
          <div class="clearfix"></div>
      </div>
      <!-- Left navigation section -->
      <section id="left-navigation">
          <!--Left navigation start-->
          <ul class="mainNav">
              <li class="active">
                  <a [routerLink]="['./']" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">
                      <i class="fa fa-dashboard"></i> <span>עמוד ראשי</span>
                  </a>
              </li>
              <li>
                  <a [routerLink]="'/users'" routerLinkActive="active">
                      <i class="fa fa-users"></i> <span>משתמשים</span>
                  </a>
              </li>
              <li>
                <a [routerLink]="'/recommenders'" routerLinkActive="active">
                    <i class="fa fa-clock-o"></i> <span>ממליצים</span>
                </a>
            </li>
              <li>
                  <a [routerLink]="'/recos'" routerLinkActive="active">
                      <i class="fa fa-star"></i>
                      <span>המלצות</span>
                  </a>
              </li>
              <li>
                  <a [routerLink]="'/payments'" routerLinkActive="active">
                      <i class="fa fa-money"></i>
                      <span>רכישות</span>
                  </a>
              </li>
              <li>
                  <a [routerLink]="'/chats'" routerLinkActive="active">
                      <i class="fa fa-comments"></i>
                      <span>צ'אט</span>
                  </a>
                  <ul>
                      <li><a href="/Admin/Chats"><i class="fa fa-globe"></i> צ'אט תמיכה כללי</a></li>
                      <!--<li><a href="/Admin/Chat/Subscriptions"><i class="fa fa-user"></i> צ'אט תמיכה למנויים</a></li>-->
                      <li><a href="/Admin/Chat/Boardcast"><i class="fa fa-bullhorn"></i> הודעת שידור</a></li>
                  </ul>
              </li>
              <li>
                  <a href="/Admin/References">
                      <i class="fa fa-external-link"></i>
                      <span>לינקים יעודיים</span>
                  </a>
              </li>
              <li>
                <a [routerLink]="'/games-groups'" routerLinkActive="active">
                  <i class="fa fa-trophy"></i> משחקים וקבוצות
                </a>
              </li>
              <li>
                  <a [routerLink]="'/advs'" routerLinkActive="active">
                      <i class="fa fa-bars"></i>
                      <span>פרסומות</span>
                  </a>
              </li>
              <li>
                  <a href="/Admin/Settings">
                      <i class="fa fa-edit"></i>
                      <span>הגדרות האתר</span>
                  </a>
              </li>
          </ul>
      </section>
      <!-- Page Main Section -->
      <section id="min-wrapper">
          <div id="main-content">
              <div class="container-fluid">
                <router-outlet></router-outlet>
              </div>
          </div>
      </section>
    </section>
  </div>
    <section *ngIf="!isLogin">
      <div class="container-fluid">
          <div class="row">
              <div class="col-md-12">
                  <div class="login-box">
                      <div class="login-content">
                          <div class="login-user-icon">
                              <i class="glyphicon glyphicon-user"></i>
                          </div>
                          <!-- Loading -->
                          <div *ngIf="isLoading">
                            <span class="spinner-grow spinner-grow-sm mr-1"></span>
                            &nbsp;
                            <span class="spinner-grow spinner-grow-sm mr-1"></span>
                            &nbsp;
                            <span class="spinner-grow spinner-grow-sm mr-1"></span>
                            &nbsp;
                            <span class="spinner-grow spinner-grow-sm mr-1"></span>
                        </div>
                      </div>
  
                      <div class="login-form">                        
                        <form [formGroup]="loginForm" class="form-horizontal ls_form">
                          <!-- Phone -->
                          <div class="input-group ls-group-input">
                            <input class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && fc.phone.errors }" type="text" placeholder="מספר פלאפון">
                            <span class="input-group-addon"><i class="fa fa-user"></i></span>
                          </div>
                          <!-- Password -->
                          <div class="input-group ls-group-input">
                            <input type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && fc.phone.errors }" placeholder="סיסמא" class="form-control" value="">
                            <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                          </div>
                          <!-- Authenticate -->
                          <div class="input-group ls-group-input login-btn-box">
                            <button mat-raised-button class="btn ls-dark-btn ladda-button col-md-12 col-sm-12 col-xs-12" (click)="onLoginSubmit()" data-style="slide-down">
                              <span class="ladda-label"><i class="fa fa-key"></i></span>
                            </button>
                          </div>
                          </form>
                      </div>
  
                  </div>
              </div>
          </div>
      </div>
      <p class="copy-right big-screen hidden-xs hidden-sm" >
          כל הזכויות שמורות ל<a href="/" style="color: #999;">וינוייס</a> &copy; 2018-2020<br />
          עוצב ונבנה על ידי <a [href]="" style="color: #999;">קבוצת Oversight</a>
      </p>
  </section>
  

</body>

