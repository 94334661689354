import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Settings from '.././global/settings';
import { ServerRoutes } from '.././global/routes';
import * as GlobalModels from '../models/global.models';
import * as AuthModels from '../models/auth.models';
import * as MainModels from '../models/main.models';
import * as UserModels from '../models/user.models';
import * as ChatModels from '../models/chat.models';

import { sha256 } from "js-sha256";

export class Users {
  http: HttpClient;
  userLocalStorage: any;
  
  constructor(httpInstance: HttpClient) {
    this.http = httpInstance;
    if(localStorage.length !== 0) {
      this.userLocalStorage = JSON.parse(localStorage.getItem(Settings.LC_CURRENT_USER_KEY));
    }
  }

  public Login(phone: string, password: string) : Observable<AuthModels.Login> {
    return this.http.post<AuthModels.Login>(`${Settings.SERVER_URL}${ServerRoutes.LOGIN}`, 
      JSON.stringify({
        phone: phone,
        password: sha256(password)
      }), { headers: Settings.HTTP_CONTENT_TYPE_JSON });
  }
  
  public Authenticate(): Observable<AuthModels.Authenticate> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<AuthModels.Authenticate>(`${Settings.SERVER_URL}${ServerRoutes.AUTHENTICATE}`, 
      JSON.stringify({
        phone: this.userLocalStorage.phone,
        token: this.userLocalStorage._authToken
      }), { headers: Settings.HTTP_CONTENT_TYPE_JSON });
    }
  }

  public GetMainData(): Observable<MainModels.MainData> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<MainModels.MainData>(`${Settings.SERVER_URL}${ServerRoutes.MAIN_DATA}`, 
        JSON.stringify({
          phone: this.userLocalStorage.phone,
          token: this.userLocalStorage._authToken
      }), { headers: Settings.HTTP_CONTENT_TYPE_JSON });
    }
  }

  public GetUsers<T>(page: number, pageSize: number, recommends: boolean, search: string): Observable<GlobalModels.DataTable<T>> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.DataTable<T>>(`${Settings.SERVER_URL}${ServerRoutes.GET_USERS}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          page,
          pageSize,
          recommends,
          search
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }

  public EditUser(id: number, first: string, last: string, mobile: string, perms: UserModels.PrivilegeTypes, password: string): Observable<UserModels.User> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<UserModels.User>(`${Settings.SERVER_URL}${ServerRoutes.EDIT_USER}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id,
          firstName: first,
          lastName: last,
          mobile,
          perms,
          password: password.length > 0 ? sha256(password) : ""
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }

  public BanUser(id: number) {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<UserModels.User>(`${Settings.SERVER_URL}${ServerRoutes.BAN_USER}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }

  public UnBanUser(id: number) {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<UserModels.User>(`${Settings.SERVER_URL}${ServerRoutes.UNBAN_USER}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }

  public GetSupportConversations(lastID: number): Observable<ChatModels.ViewAdminClassModel[]> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<ChatModels.ViewAdminClassModel[]>(`${Settings.SERVER_URL}${ServerRoutes.GET_SUPPORT_CONVERSATIONS}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          lastID
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }

  public GetSupportConversation(id: number, lastID: number): Observable<ChatModels.ConvsViewModel> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<ChatModels.ConvsViewModel>(`${Settings.SERVER_URL}${ServerRoutes.GET_PRIVATE_SUPPORT_CHAT}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id: +id,
          lastID
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }

  public SendChatMessage(id: number, message: string) {
    if(this.userLocalStorage !== undefined) {
      return this.http.post(`${Settings.SERVER_URL}${ServerRoutes.SEND_SUPPORT_CHAT}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id: +id,
          message
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }
}