import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Router, ActivatedRoute, CanActivate, CanDeactivate, ActivatedRouteSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as Settings from './global/settings';
import { ServerRoutes, AppRoutes } from './global/routes';
import { merge, Observable, of as observableOf } from 'rxjs';
import * as AuthModels from './models/auth.models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

// Shared
import { StatusCodes } from '../../../shared/status.codes';

// Utilities
import { removeSpaces } from '../app/utils/validators';

// Systems
import { Notifications } from './systems/notifications';
import { Users } from './systems/users';


// Notifications
const loginNotifications = {
  [StatusCodes.INTERNAL]: "‫אופס! נתקלנו בשגיאה. אנא נסה שוב בהמשך.‬‎",
  [StatusCodes.FIELDS_REQURIED]: "מלא את השדות המבוקשים.",
  [StatusCodes.WORNG_LOGIN_DETAILS]: "פרטי התחברות שגויים.",
  [StatusCodes.OK]: "ההתחברות בוצעה בהצלחה!"
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  Settings = Settings;

  private users: Users;
  private notifications: Notifications;

  private loginForm: FormGroup;

  submitted: boolean = false;
  isLogin: boolean = false;
  isLoading: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private http: HttpClient) {

    this.users = new Users(this.http);
    this.notifications = new Notifications(this.snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);

    try {
      this.users.Authenticate().pipe(
        map((data) => { 
          return data.privilege; 
        }),
        catchError(() => { 
          return observableOf([]); 
        })
      ).subscribe((complete) => { 
        this.isLogin = (complete !== undefined && complete >= AuthModels.PermissionType.STAFF); 
      });
    } catch(err) {
  
    } finally {
      if(!this.isLogin) {
        this.loginForm = this.formBuilder.group({
          phone: ['', [Validators.required, removeSpaces]],
          password: ['', [Validators.required, removeSpaces]],
        });
      }
    }
  }

  title = 'admin';

  ngOnInit() {

  }

  get fc() {
    return this.loginForm.controls;
  }

  onLoginSubmit(): void {

    this.submitted = true;

    if(this.loginForm.invalid) {
      this.notifications.Error(loginNotifications[StatusCodes.FIELDS_REQURIED]);
      return;
    }

    this.isLoading = true;

    this.users.Login(this.fc.phone.value, this.fc.password.value).pipe(
      map((data) => { 
        if(data.privilege >= AuthModels.PermissionType.STAFF) 
          localStorage.setItem("_currentUser", JSON.stringify(data));
        return data.privilege; 
      }),
      catchError((err) => { 
        this.notifications.Error(err.error.error_code === undefined ? 
          loginNotifications[StatusCodes.INTERNAL] : loginNotifications[err.error.error_code]);
        return observableOf([]); 
      })
    ).subscribe((complete) => { 
      if(complete !== undefined && complete >= AuthModels.PermissionType.STAFF) {
        this.isLogin = true;
        this.notifications.Ok(loginNotifications[StatusCodes.OK]);
        setTimeout(() => {
          this.isLogin = true;
        }, Settings.ROUTING_TIMER_INTERVAL);
        return;
      }
      else {
        this.isLoading = false;
        this.notifications.Error(loginNotifications[StatusCodes.WORNG_LOGIN_DETAILS]);
      }
    });

  }
}
