<div class="row">
  <div class="col-md-12">
    <h2 class="ls-top-header">ניהול משחקים וקבוצות</h2>
  </div>
</div>

<!-- Games -->
<div class="panel-dark" style="border: unset !important;">
  <div class="panel-heading">
      <span class="panel-title">משחקים</span>
  </div>
  <div class="panel-body" dir="rtl">
    <div class="row">
      <div class="col-md-12">
        <!-- Payments History Table. -->
        <mat-form-field style="text-align: right;width: 40%;">
          <mat-label>חיפוש</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="הזן טקסט חיפוש..." #input>
        </mat-form-field>
        <button class="btn-submit btn-default" style="margin-right: 20px;" (click)="onCreateGameDialog()" mat-flat-button>
          <i class="fa fa-plus"></i> משחק חדש
        </button>
        <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
          <table class="table-striped table-responsive-md" mat-table [dataSource]="gamesDataSource"
            matSortActive="started" matSortDisableClear matSortDirection="desc" #activeUsersSort="matSort" matSort>
            <!-- Title -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> משחק </th>
              <td mat-cell *matCellDef="let game" class="td-details">
                <img [src]="game.iconSrc" style="width: 24px; height: 24px"/> {{ game.name }}
              </td>
            </ng-container>
            <!-- Options -->
            <ng-container matColumnDef="options">
              <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
              <td mat-cell *matCellDef="let game">
                <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #menu="matMenu">
                  <button class="reco-option-btn" (click)="onEditGameDialog(game)" mat-menu-item>עריכה</button>
                  <button class="reco-option-btn delete-btn" (click)="onDeleteGameDialog(game)" mat-menu-item>מחיקה</button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="gamesColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: gamesColumns;"></tr>

            <div *matNoDataRow style="margin-right: 4px;">
              <h4>אין נתונים התואמים את החיפוש "{{ input.value }}"</h4>
            </div>
          </table>
          <!-- Not found items situation -->
          <div style="margin-right: 10px" *ngIf="gamesTotal <= 0">
            <h4>אין משחקים לתצוגה.</h4>
          </div>
          <!-- Paginator setup -->
          <mat-paginator #gamesPaginator
            [length]="gamesTotal"
            [pageSize]="Settings.DEFAULT_PAGE_SIZE"
            [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
            [showFirstLastButtons]="true">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Groups -->
<div class="panel-dark" style="border: unset !important;">
  <div class="panel-heading">
      <span class="panel-title">קבוצות</span>
  </div>
  <div class="panel-body" dir="rtl">
    <div class="row">
      <div class="col-md-12">
        <!-- Payments History Table. -->
        <mat-form-field style="text-align: right;width: 40%;">
          <mat-label>חיפוש</mat-label>
          <input matInput (keyup)="filterGroups($event)" placeholder="הזן שם משתמש / מספר פלאפון" #input>
        </mat-form-field>
        <button class="btn-submit btn-default" (click)="onCreateGroupDialog()" style="margin-right: 20px;" mat-flat-button>
          <i class="fa fa-plus"></i> קבוצה חדשה
        </button>
        <div class="table-responsive" style="box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;">
          <table class="table-striped table-responsive-md" mat-table [dataSource]="groupsData"
            matSortActive="started" matSortDisableClear matSortDirection="desc" #groupsSort="matSort" matSort>
            <!-- Title -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> קבוצה </th>
              <td mat-cell *matCellDef="let group" class="td-details">
                <img [src]="group.imageSrc" class="reco-group-img"/> {{ group.name }}
              </td>
            </ng-container>
            <!-- Game -->
            <ng-container matColumnDef="game">
              <th mat-header-cell *matHeaderCellDef style="padding-right:13px"> משחק </th>
              <td mat-cell *matCellDef="let group" class="td-details">
                <img [src]="group.game.iconSrc"/> {{ group.game.name }}
              </td>
            </ng-container>
            <!-- Options -->
            <ng-container matColumnDef="options">
              <th class="table-header" mat-header-cell *matHeaderCellDef> אפשרויות </th>
              <td mat-cell *matCellDef="let group">
                <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #menu="matMenu">
                  <button class="reco-option-btn" (click)="onEditGroupDialog(group)" mat-menu-item>עריכה</button>
                  <button class="reco-option-btn delete-btn" (click)="onDeleteGroupDialog(group)" mat-menu-item>מחיקה</button>
                </mat-menu>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="groupColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: groupColumns;"></tr>

          </table>
          <!-- Not found items situation. -->
          <div style="margin-right: 10px" *ngIf="groupsTotal <= 0">
            <h4>אין קבוצות לתצוגה</h4>
          </div>
          
          <div *ngIf="groupsBadSearch" style="margin-right: 4px;">
            <h4>אין נתונים התואמים את החיפוש "{{ groupSearch }}"</h4>
          </div>
        <!-- Paginator setup. -->
        <mat-paginator 
          #groupsPaginator
          [length]="groupsTotal"
          [pageSize]="Settings.DEFAULT_PAGE_SIZE"
          [pageSizeOptions]="Settings.PAGE_SIZE_OPTIONS"
          [showFirstLastButtons]="true"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>