import * as GlobalModels from '../models/global.models';
import * as UserModels from '../models/user.models';

export enum StatusTypes {
  NONE = 0,
  OPEN = 1,
  ENDED = 2,
  PENDING = 3,
  CANCELED = 4
};

export enum ResultTypes {
  
    NONE = 0,
    FIRST_WIN = 1,
    SECOND_WIN = 2,
    TIE = 3
};

export interface Reco {

    id: number;
    creator: UserModels.RecommendModel;
    game: GlobalModels.Game;
    title: string;
    group1: GlobalModels.Group;
    ratio1: number;
    group2: GlobalModels.Group;
    ratio2: number;
    ratioBetween: number;
    created: string;
    gameTime: Date;
    result: ResultTypes;
    status: StatusTypes;
    price: number;
};
