// Angular libraries
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Global
import * as Settings from '../../../global/settings';
import { ServerRoutes } from '../../.././global/routes';

// Shared
import { StatusCodes } from '../../../../../../shared/status.codes';

// Models
import * as GlobalModels from '../../../models/global.models';

// Systems
import { Notifications } from '../../../systems/notifications';
import { Global } from '../../../systems/global';
import { NONE_TYPE } from '@angular/compiler';

// Notifications
const deleteGroupNotifications = {
  [StatusCodes.INTERNAL]: "‫אופס! נתקלנו בשגיאה. אנא נסה שוב בהמשך.‬‎",
  [StatusCodes.INVALID_TOKEN]: "הזדהה בכדי לבצע פעולה זו.",
  [StatusCodes.OBJECT_NOT_FOUND]: "קבוצה לא נמצאה.",
  [StatusCodes.OK]: "הקבוצה נמחקה!"
};

@Component({
  selector: 'app-delete-group',
  templateUrl: './delete-group.component.html',
  styleUrls: ['../../dialogs.style.scss']
})
export class DeleteGroupComponent implements OnInit {

  private notifications: Notifications;
  private global: Global;

  groupDeleted: GlobalModels.Group;

  loading = false;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DeleteGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GlobalModels.Group) { 
      this.groupDeleted = {
        id: data.id,
        name: data.name,
        imageSrc: data.imageSrc,
        game: undefined
      };
      this.global = new Global(this.http, true);
      this.notifications = new Notifications(snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);
   }


  ngOnInit(): void {
  }

  onDeleteGroupSubmitted(): void {

    this.loading = true;

    this.global.DeleteGroup(this.groupDeleted.id).subscribe(
      (data) => {
        this.notifications.Ok(deleteGroupNotifications[StatusCodes.OK]);
        this.dialogRef.close(true);
      },
      (err) => {
        this.notifications.Error(err.error.error_code === undefined ? 
          deleteGroupNotifications[StatusCodes.INTERNAL] : deleteGroupNotifications[err.error.error_code]);
      }
    );
  }
  onCloseDialog(): void {
    this.dialogRef.close();
  }

}
