<!--=================-- [ Delete Game ] --=================-->
<div class="dialog-inner-content">
  <!-- Dialog Title -->
  <h1 class="dialog-title" mat-dialog-title>
    מחיקת משחק
  </h1>
  <div mat-dialog-content>
    <!-- Image Uploaded -->

    למחוק את המשחק <img [src]="gameDeleted.iconSrc" style="width: 16px; height: 16px">&nbsp;<b>{{ gameDeleted.name }}</b>?
  </div>
</div>
<!-- Dialog Actions -->
<div mat-dialog-actions style="text-align:left">
  <!-- Close -->
  <button mat-button class="btn-close" (click)="onCloseDialog()">יציאה</button>
  <!-- Submit -->
  <button (click)="onDeleteSubmitted()" class="dialog-btn btn-delete" mat-button>
    מחיקה
  </button>
</div>