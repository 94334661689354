import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as signalR from "@microsoft/signalr";

import * as Settings from '../src/app/global/settings';

import { AuthModel } from './app/models/user.models';

import { ChatService } from './app/services/chat.service';

var userLocalStorage: AuthModel;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
  if(localStorage.length !== 0) {
    userLocalStorage = JSON.parse(localStorage.getItem(Settings.LC_CURRENT_USER_KEY)) as AuthModel;
  }
  
  if(userLocalStorage !== undefined) {
  
    var hubConnection: signalR.HubConnection;
  
    hubConnection = new signalR.HubConnectionBuilder().withUrl(`${Settings.MAIN_SERVER_URL}/chatsHub?phone=${userLocalStorage.phone}&token=${userLocalStorage._authToken}`).build();
  
    hubConnection
    .start()
    .then(() => console.log('Connection started'))
    .catch(err => console.log('Error while starting connection: ' + err))
  
    const chatService = ref.injector.get(ChatService);
  
    hubConnection.on("OnAdminReceiveMessage", (sender: number, message: string) => {
      chatService.onReceivedChatMessage({ sender, message });
    });
  }

 })
  .catch(err => console.error(err));
