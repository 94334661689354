// Angular libraries
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

// Global
import * as Settings from '../global/settings';

// Shared
import { StatusCodes } from '../../../../shared/status.codes';

// Utilities
import * as Formats from '../utils/formats';

// Models
import * as UserModels from '../models/user.models';
import * as PaymentModels from '../models/payment.models';

// Systems
import { Notifications } from '../systems/notifications';
import { Users } from '../systems/users';
import { Payments } from '../systems/payments';

// Components
import { ApproveDrawComponent } from '../recommenders/approve-draw/approve-draw.component';
import { DeclineDrawComponent } from '../recommenders/decline-draw/decline-draw.component';

@Component({
  selector: 'app-recommenders',
  templateUrl: './recommenders.component.html',
  styleUrls: ['./recommenders.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecommendersComponent implements OnInit, AfterViewInit {

  // Implements
  Settings = Settings;
  Formats = Formats;
  UserPrivileges = UserModels.PrivilegeTypes;
  UserStatusTypes = UserModels.StatusTypes;

  // Systems
  private users: Users;
  private payments: Payments;
  private notifications: Notifications;

  recommendsBadSearch: string = "";

  // Recommends
  recommendsData: UserModels.Recommend[] = [];
  recommendsColumns: string[] = [ 
    'fullName',
    'mobile',
    'plan',
    'withdraw_status'
  ];
  totalRecommends = Settings.DEFAULT_LOADED_VALUE;

  // Pending Requests
  pendingRequestsData: PaymentModels.PaymentTrack[] = [];
  pendingRequestsColumns: string[] = [
    "user",
    "created",
    "amount",
    "status",
    "options"
  ];
  totalPendingRequests = Settings.DEFAULT_LOADED_VALUE;

  // Requests History
  reuqestsHistoryData: PaymentModels.PaymentTrack[] = [];
  totalRequestsHistory = Settings.DEFAULT_LOADED_VALUE;
  requestsHistoryColumns: string[] = this.pendingRequestsColumns.filter(i => i !== "options");

  // Paginator's
  @ViewChild('recommendsPaginator') recommendsPaginator: MatPaginator;
  @ViewChild('pendingRequestsPaginator') pendingRequestsPaginator: MatPaginator;
  @ViewChild('requestsHistoryPaginator') requestsHistoryPaginator: MatPaginator;

  // Sort's
  @ViewChild('recommendsSort') recommendsSort: MatSort;
  @ViewChild('pendingRequestsSort') pendingRequestsSort: MatSort;
  @ViewChild('requestsHistorySort') requestsHistorySort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private http: HttpClient,
    private dialog: MatDialog) {

      this.users = new Users(this.http);
      this.payments = new Payments(this.http);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // =================-- [ Recommends ] --=================
    Formats.translatePaginator(this.recommendsPaginator);

    merge(this.recommendsSort.sortChange, this.recommendsPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.users!.GetUsers<UserModels.Recommend[]>(this.recommendsPaginator.pageIndex + 1, this.recommendsPaginator.pageSize, true, "");
        }),
        map(data => {
          this.totalRecommends = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          this.totalRecommends = 0;
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.recommendsData = data.reverse();
      }
    );

    // =================-- [ Pending Requests ] --=================
    Formats.translatePaginator(this.pendingRequestsPaginator);

    merge(this.pendingRequestsSort.sortChange, this.pendingRequestsPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.payments!.GetPaymentsTrack(PaymentModels.PaymentTrackStatusTypes.PENDING, this.recommendsPaginator.pageIndex + 1, this.recommendsPaginator.pageSize);
        }),
        map(data => {
          this.totalPendingRequests = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          this.totalPendingRequests = 0;
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.pendingRequestsData = data.reverse();
      }
    );

    // =================-- [ Requests History ] --=================
    Formats.translatePaginator(this.requestsHistoryPaginator);

    merge(this.requestsHistorySort.sortChange, this.requestsHistoryPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.payments!.GetPaymentsTrack(PaymentModels.PaymentTrackStatusTypes.NONE, this.recommendsPaginator.pageIndex + 1, this.recommendsPaginator.pageSize);
        }),
        map(data => {
          this.totalRequestsHistory = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          this.totalRequestsHistory = 0;
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.reuqestsHistoryData = data.reverse();
      }
    );
  }

  filterUsers(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.replace("-", "");
    if(/\S/.test(filterValue) || filterValue == "") {
      this.users!.GetUsers<any>(this.recommendsPaginator.pageIndex + 1, this.recommendsPaginator.pageSize, true, filterValue).pipe(
        map((data) => { 
          this.recommendsBadSearch = "";
          return data.items; 
        }),
        catchError((err) => { 
          this.recommendsBadSearch = filterValue;
          return observableOf([]); 
        })
      ).subscribe((data) => { 
        this.recommendsData = data.reverse();
      });
    }
  }

  onApproveDrawDialogShow(id: number, user: string, amount: number): void {
    const dialogRef = this.dialog.open(ApproveDrawComponent, {
      width: '400px',
      data: { id, user, amount }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.ngAfterViewInit();
      }
    });
  }

  onDeclineDrawDialogShow(id: number, user: string, amount: number): void {
    const dialogRef = this.dialog.open(DeclineDrawComponent, {
      width: '400px',
      data: { id, user, amount }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.ngAfterViewInit();
      }
    });
  }


}
