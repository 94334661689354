<!--=================-- [ Mark Incorrect Reco ] --=================-->
<div class="dialog-inner-content">
  <h1 class="dialog-title" mat-dialog-title>
    סימון המלצה
  </h1>
  <div mat-dialog-content style="padding-bottom: 10px;">
    <div style="margin-top: 2px;">
      <div style="text-align:center;padding-bottom: 10px">
        <p style="margin-top: 3px;margin: 0px 0px 0px !important;padding-bottom: 10px;">
          <img [src]="recoMarked.game.iconSrc" style="height: 20px; width: 20px;"/>&nbsp;{{ recoMarked.title }}
        </p>
        <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="recoMarked.ratio1">
          <img [src]="recoMarked.group1.imageSrc" class="reco-group-img"/>
          {{ recoMarked.group1.name }} 
        </span>
        &nbsp;<b style="font-size: 15px;">.Vs</b>&nbsp;
        <span matTooltipPosition="above" matTooltipClass="tooltip-fixed" [matTooltip]="recoMarked.ratio2">
          <img [src]="recoMarked.group2.imageSrc" class="reco-group-img"/>
          {{ recoMarked.group2.name }} 
        </span>
      </div>
    </div>
    <span>לסמן את ההמלצה של <b>{{ recoMarked.creator.fullName }}</b> כטועה?</span>
  </div>
</div>
<div mat-dialog-actions style="text-align:left">
  <button mat-button class="btn-close" (click)="onCloseDialog()">יציאה</button>
  <button (click)="onMarkIncorrectSubmitted()" class="dialog-btn btn-delete" mat-button>
    אישור
  </button>
</div>