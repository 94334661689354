// Angular libraries
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';

// Global
import * as Formats from '../../../app/utils/formats';
import * as Settings from '../../global/settings';
import { ServerRoutes } from '../../global/routes';

// Shared
import { StatusCodes } from '../../../../../shared/status.codes';

// Models
import * as GlobalModels from '../../models/global.models';

// Systems
import { Notifications } from '../../systems/notifications';
import { Global } from '../../systems/global';

// Notifications
const createAdvNotifications = {
  [StatusCodes.INTERNAL]: "‫אופס! נתקלנו בשגיאה. אנא נסה שוב בהמשך.‬‎",
  [StatusCodes.INVALID_TOKEN]: "הזדהה בכדי לבצע פעולה זו.",
  [StatusCodes.FIELDS_REQURIED]: "שגיאה במילוי השדות.",
  [StatusCodes.EXTENSION_NOT_ALLOWED]: "הקובץ שבחרת לא מתאים לדרישות.",
  [StatusCodes.WRONG_ADV_TITLE]: "כותרת לא תקינה.",
  [StatusCodes.WRONG_ADV_LINK]: "קישור לא תקין.",
  [StatusCodes.INVALID_ADV_IMAGE]: "לא נבחרה תמונה תקינה.",
  [StatusCodes.OK]: "הפרסומת נוצרה בהצלחה!"
};

@Component({
  selector: 'app-create-adv',
  templateUrl: './create-adv.component.html',
  styleUrls: ['../dialogs.style.scss']
})
export class CreateAdvComponent implements OnInit {

  Formats = Formats;
  createAdvForm: FormGroup;

  private notifications: Notifications;
  private global: Global;

  loading = false;
  submitted = false;

  uploadImageHash = "";

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateAdvComponent>) {

      this.global = new Global(this.http, true);
      this.notifications = new Notifications(snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);

      this.createAdvForm = this.formBuilder.group({
        title: ['', [Validators.required]],
        display: ['', [Validators.required]],
        link: ['', [Validators.required]]
      });
  }

  ngOnInit(): void {
  }

  onCreateAdvSubmitted(): void {
    this.submitted = true;

    if(this.createAdvForm.invalid) {
      this.notifications.Error(createAdvNotifications[StatusCodes.FIELDS_REQURIED]);
      return;
    }

    this.loading = true;

    let imageHash = "";
    if(this.uploadImageHash.length > 0)
      imageHash = this.uploadImageHash.slice(this.uploadImageHash.lastIndexOf("/") + 1);

    this.global.CreateAdvertisment(this.fc.title.value, this.fc.link.value, imageHash, this.fc.display.value).subscribe(
      (data) => {
        this.notifications.Ok(createAdvNotifications[StatusCodes.OK]);
        this.dialogRef.close(data);
      },
      (err) => {
        console.log(err);
        this.notifications.Error(err.error.error_code === undefined ? 
          createAdvNotifications[StatusCodes.INTERNAL] : createAdvNotifications[err.error.error_code]);
      }
    )
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const fileName = event.target.files[0].name;

      var re = /(?:\.([^.]+))?$/;

      if(!Settings.ALLOWED_IMAGE_EXTENSIONS.includes(re.exec(fileName)[1])) {
        this.notifications.Error(createAdvNotifications[StatusCodes.EXTENSION_NOT_ALLOWED]);
        return;
      }
    }
  }

  onFileComplete(fileHash: string): void {
    this.uploadImageHash = `${Settings.SERVER_URL}${ServerRoutes.VIEW_TEMP_IMAGE}/${fileHash}`;
  }

  
  onCloseDialog(): void {
    this.dialogRef.close();
  }
  
  get fc() {
    return this.createAdvForm.controls;
  }

}
