// Angular libraries
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Global
import * as Settings from '../../global/settings';
import { ServerRoutes } from '../.././global/routes';
import { removeSpaces, MustMatch } from '../../utils/validators';

// Shared
import { StatusCodes } from '../../../../../shared/status.codes';
import * as Formats from '../../utils/formats';

// Models
import * as GlobalModels from '../../models/global.models';

// Systems
import { Notifications } from '../../systems/notifications';
import { Global } from '../../systems/global';

import * as UserModels from '../../models/user.models';
import { Users } from '../../systems/users';
import { formatScore } from '../../utils/formats';

// Notifications
const editUserNotifications = {
  [StatusCodes.INTERNAL]: "‫אופס! נתקלנו בשגיאה. אנא נסה שוב בהמשך.‬‎",
  [StatusCodes.INVALID_TOKEN]: "הזדהה בכדי לבצע פעולה זו.",
  [StatusCodes.FIELDS_REQURIED]: "שגיאה במילוי השדות.",
  [StatusCodes.OBJECT_NOT_FOUND]: "משתמש לא נמצא.",
  [StatusCodes.INVALID_PHONE_NUMBER]: "מספר פלאפון שגוי.",
  [StatusCodes.LONG_FIRST_OR_LAST]: "שם או שם משפחה מכילים יותר מדי תווים.",
  [StatusCodes.INVALID_PASSWORD]: `סיסמא שגויה.`,
  [StatusCodes.USER_ALREADY_EXISTS]: "מספר פלאפון תפוס.",
  [StatusCodes.OK]: "פרטי המשתמש נשמרו!"
};

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['../dialogs.style.scss']
})
export class EditUserComponent implements OnInit {

  submitted = false;
  
  editUserForm: FormGroup;

  Formats = Formats;
  PrivilegeTypes = UserModels.PrivilegeTypes;

  users: Users;
  notifications: Notifications;

  editedUserID: number;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModels.User) {

      this.users = new Users(this.http);
      this.notifications = new Notifications(snackBar, Settings.NOTIF_CLOSE_TEXT, Settings.NOTIF_DURATION);

      var userName = this.data.fullName.split(" ");
      this.editedUserID = this.data.id;
  
      this.editUserForm = this.formBuilder.group({
        firstName: [userName[0], [Validators.required, removeSpaces]],
        lastName: [userName[1], [Validators.required, removeSpaces]],
        phone: [this.data.phone, [Validators.required, removeSpaces, Validators.minLength(10), Validators.maxLength(10)]],
        perms: [this.data.privilege, [Validators.required]],
        password: ['', [
          Validators.minLength(Settings.SecuritySettings.MIN_PASSWORD_LENGTH),
          Validators.maxLength(Settings.SecuritySettings.MAX_PASSWORD_LENGTH),
          removeSpaces
        ]],
        confirmPassword: ['', [removeSpaces]],
      }, { validator: MustMatch('password', 'confirmPassword') });
  }

  ngOnInit(): void {
  }

  onEditUserSubmitted(): void {
    this.submitted = true;

    if(this.editUserForm.invalid) {
      this.notifications.Error(editUserNotifications[StatusCodes.FIELDS_REQURIED]);
      return;
    }

    //if(passwd === undefined)
    //  passwd

    this.users.EditUser(this.editedUserID, this.fc.firstName.value, this.fc.lastName.value, 
      this.fc.phone.value, +this.fc.perms.value, this.fc.password.value).subscribe(
        (data) => {
          this.notifications.Ok(editUserNotifications[StatusCodes.OK]);
          this.dialogRef.close(data);
        },
        (err) => {
          this.notifications.Error(err.error.error_code === undefined ? 
            editUserNotifications[StatusCodes.INTERNAL] : editUserNotifications[err.error.error_code]);
        }
      );
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  get fc() {
    return this.editUserForm.controls;
  }

}
