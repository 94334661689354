export enum PermissionType {
  NONE = 0,
  STAFF = 1,
  ADMIN = 2
};
export interface Login {
  privilege: PermissionType;
  _authToken: string;
};
export interface Authenticate {
  privilege: PermissionType;
  firstName: string;
  lastName: string;
};