import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Settings from '.././global/settings';
import { ServerRoutes } from '.././global/routes';

// Models
import * as GlobalModels from '../models/global.models';

export class Global {
  
  http: HttpClient;
  userLocalStorage: any;
  
  constructor(httpInstance: HttpClient, getLocalStorage: boolean) {
    this.http = httpInstance;
    if(getLocalStorage) {
      this.userLocalStorage = JSON.parse(localStorage.getItem(Settings.LC_CURRENT_USER_KEY));
    }
  }

  public GetGames(): Observable<GlobalModels.Game[]> {
    return this.http.get<GlobalModels.Game[]>(`${Settings.SERVER_URL}${ServerRoutes.GLOBAL_TYPES}`, { params: { games: "true", groups: "false" }});
  }
  public GetGroups(): Observable<GlobalModels.Group[]> {
    return this.http.get<GlobalModels.Group[]>(`${Settings.SERVER_URL}${ServerRoutes.GLOBAL_TYPES}`, { params: { games: "false", groups: "true" }});
  }
  public GetGroupsTable(page: number, pageSize: number, search: string): Observable<GlobalModels.DataTable<GlobalModels.Group[]>> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.DataTable<GlobalModels.Group[]>>(`${Settings.SERVER_URL}${ServerRoutes.GET_GROUPS}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          page,
          pageSize,
          search
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }
  public GetGroupsAndGames(): Observable<GlobalModels.GameGroup> {
    return this.http.get<GlobalModels.GameGroup>(`${Settings.SERVER_URL}${ServerRoutes.GLOBAL_TYPES}`, { params: { games: "true", groups: "true" }});
  }

  public CreateGame(name: string, image: string): Observable<GlobalModels.Game> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.Game>(`${Settings.SERVER_URL}${ServerRoutes.CREATE_GAME}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          name,
          image
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }
  public EditGame(id: number, name: string, image: string): Observable<GlobalModels.Game> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.Game>(`${Settings.SERVER_URL}${ServerRoutes.EDIT_GAME}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id,
          name,
          image
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }
  public DeleteGame(id: number) {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.Game>(`${Settings.SERVER_URL}${ServerRoutes.DELETE_GAME}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id,
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }
  public CreateGroup(name: string, game: number, image: string): Observable<GlobalModels.Group> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.Group>(`${Settings.SERVER_URL}${ServerRoutes.CREATE_GROUP}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          name,
          game,
          image
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }
  public EditGroup(id: number, name: string, game: number, image: string): Observable<GlobalModels.Group> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.Group>(`${Settings.SERVER_URL}${ServerRoutes.EDIT_GROUP}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id,
          name,
          game,
          image
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }
  public DeleteGroup(id: number) {
    if(this.userLocalStorage !== undefined) {
      return this.http.post(`${Settings.SERVER_URL}${ServerRoutes.DELETE_GROUP}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id,
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }
  public GetAdvertisementsTable(page: number, pageSize: number): Observable<GlobalModels.DataTable<GlobalModels.Adv[]>> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.DataTable<GlobalModels.Adv[]>>(`${Settings.SERVER_URL}${ServerRoutes.GET_ADVERTISEMENTS}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          page,
          pageSize
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }
  public CreateAdvertisment(title: string, link: string, image: string, display: GlobalModels.AdvDisplayTypes): Observable<GlobalModels.Adv> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.Adv>(`${Settings.SERVER_URL}${ServerRoutes.CREATE_ADVERTISMENT}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          title,
          link,
          image,
          display: +display
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  } 
}

