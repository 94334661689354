// Angular libraries
import { Component, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { from, merge, Observable, of as observableOf } from 'rxjs';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

// Global
import * as Settings from '../global/settings';

// Shared
import { StatusCodes } from '../../../../shared/status.codes';

// Utilities
import * as Formats from '../utils/formats';

// Models
import * as GlobalModels from '../models/global.models';

// Systems
import { Notifications } from '../systems/notifications';
import { Global } from '../systems/global';

// =================-- [ Dialogs ] --=================

// Games
import { CreateGameComponent } from './games/create-game/create-game.component';
import { EditGameComponent } from './games/edit-game/edit-game.component';
import { DeleteGameComponent } from './games/delete-game/delete-game.component';

// Groups
import { CreateGroupComponent } from './groups/create-group/create-group.component';
import { EditGroupComponent } from './groups/edit-group/edit-group.component';
import { DeleteGroupComponent } from './groups/delete-group/delete-group.component';

@Component({
  selector: 'app-games',
  templateUrl: './games-groups.component.html',
  styleUrls: ['./games-groups.component.scss']
})
export class GamesGroupsComponent implements OnInit, AfterViewInit {

  // Implements
  Settings = Settings;
  Formats = Formats;

  // Systems
  private global: Global;

  // Data
  globalTypes: GlobalModels.GameGroup;
  groupSearch: string = "";
  groupsBadSearch: boolean = false;

  // Games
  gamesDataSource = new MatTableDataSource<GlobalModels.Game>();
  gamesColumns: string[] = [ 
    'title',
    'options'
  ];
  gamesTotal = Settings.DEFAULT_LOADED_VALUE;

  // Groups
  groupsData: GlobalModels.Group[] = [];
  groupColumns: string[] = [ 
    'title',
    'game',
    'options'
  ];
  groupsTotal = Settings.DEFAULT_LOADED_VALUE;

  // Paginators
  @ViewChild('gamesPaginator') gamesPaginator: MatPaginator;
  @ViewChild('groupsPaginator') groupsPaginator: MatPaginator;

  // Sorts
  @ViewChild('groupsSort') groupsSort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
    public dialog: MatDialog,
    private http: HttpClient) {

      this.global = new Global(this.http, true);
      try {
        this.global.GetGames().pipe(
          map((data: GlobalModels.Game[]) => { 
            return data; 
          })).subscribe((data) => {
            this.gamesDataSource = new MatTableDataSource(data.reverse());  
            this.gamesTotal = data.length;
          }
        );
      } catch(err) { }
  }

  ngOnInit(): void {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gamesDataSource.filter = filterValue.trim().toLowerCase();
  }

  ngAfterViewInit(): void {
    // =================-- [ Groups ] --=================
    Formats.translatePaginator(this.groupsPaginator);

    merge(this.groupsSort.sortChange, this.groupsPaginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.global.GetGroupsTable(this.groupsPaginator.pageIndex + 1, this.groupsPaginator.pageSize, this.groupSearch);
        }),
        map(data => {
          this.groupsTotal = data.totalItems;
          return data.items;
        }),
        catchError(() => {
          return observableOf([]);
        })
    ).subscribe(
      (data) => { 
        this.groupsData = data;
      }
    );
  }

  filterGroups(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(/\S/.test(filterValue) || filterValue == "") {
      this.groupSearch = filterValue;
      this.global!.GetGroupsTable(this.groupsPaginator.pageIndex + 1, this.groupsPaginator.pageSize, filterValue).pipe(
        map((data) => { 
          this.groupsTotal = data.totalItems;
          this.groupsBadSearch = false;
          return data.items; 
        }),
        catchError((err) => { 
          this.groupsBadSearch = true;
          return observableOf([]); 
        })
      ).subscribe((data) => { 
        this.groupsData = data;
      });
    }
  }

  onCreateGameDialog(): void {
    const dialogRef = this.dialog.open(CreateGameComponent, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        this.gamesDataSource.data.unshift(result);
        this.gamesDataSource._updateChangeSubscription();
      }
    });
  }

  onEditGameDialog(game: GlobalModels.Game): void {
    const dialogRef = this.dialog.open(EditGameComponent, {
      width: '400px',
      data: game
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        let idx = this.gamesDataSource.data.findIndex(i => i.id === game.id);
        this.gamesDataSource.data[idx] = result;
        this.gamesDataSource._updateChangeSubscription();
      }
    });
  }

  onDeleteGameDialog(game: GlobalModels.Game): void {
    const dialogRef = this.dialog.open(DeleteGameComponent, {
      width: '400px',
      data: game
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this.gamesDataSource.data = this.gamesDataSource.data.filter(i => i !== game);
        this.gamesDataSource._updateChangeSubscription();
      }
    });
  }

  onCreateGroupDialog(): void {
    const dialogRef = this.dialog.open(CreateGroupComponent, {
      width: '400px',
      data: this.gamesDataSource.data
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        this.ngAfterViewInit();
      }
    });
  }

  onEditGroupDialog(group: GlobalModels.Group): void {
    const dialogRef = this.dialog.open(EditGroupComponent, {
      width: '400px',
      data: {
        games: this.gamesDataSource.data,
        group
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(typeof(result) == 'object') {
        let idx = this.groupsData.findIndex(i => i.id === result.id);
        this.groupsData[idx] = result;
        this.ngAfterViewInit();
      }
    });
  }

  onDeleteGroupDialog(group: GlobalModels.Group): void {
    const dialogRef = this.dialog.open(DeleteGroupComponent, {
      width: '400px',
      data: group
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === true) {
        this.groupsData = this.groupsData.filter(i => i !== group);
        this.ngAfterViewInit();
      }
    });
  }

}
