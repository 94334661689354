import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Settings from '.././global/settings';
import { ServerRoutes } from '.././global/routes';
import * as GlobalModels from '../models/global.models';
import * as AuthModels from '../models/auth.models';
import * as PaymentModels from '../models/payment.models';
import { sha256 } from "js-sha256";

export class Payments {
  http: HttpClient;
  userLocalStorage: any;
  
  constructor(httpInstance: HttpClient) {
    this.http = httpInstance;
    if(localStorage.length !== 0) {
      this.userLocalStorage = JSON.parse(localStorage.getItem(Settings.LC_CURRENT_USER_KEY));
    }
  }

  public GetPayments(status: PaymentModels.StatusTypes, type: PaymentModels.PaymentTypes, page: number, pageSize: number) : Observable<GlobalModels.DataTable<PaymentModels.AdminViewModel[]>> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.DataTable<PaymentModels.AdminViewModel[]>>(`${Settings.SERVER_URL}${ServerRoutes.GET_PAYMENTS}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          page,
          pageSize,
          status,
          type
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON });
    }
  }

  public GetPaymentsTrack(status: PaymentModels.PaymentTrackStatusTypes, page: number, pageSize: number) : Observable<GlobalModels.DataTable<PaymentModels.PaymentTrack[]>> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.DataTable<PaymentModels.PaymentTrack[]>>(`${Settings.SERVER_URL}${ServerRoutes.GET_PAYMENTS_TRACK}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          page,
          pageSize,
          status
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON });
    }
  }

  public CompleteDrawRequest(id: number, approve: boolean) {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.DataTable<PaymentModels.PaymentTrack[]>>(`${Settings.SERVER_URL}${ServerRoutes.COMPLETE_DRAW_REQUEST}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id: +id,
          approve
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON });
    }

  }

}