import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as Settings from '.././global/settings';
import { ExternalRoutes, ServerRoutes, AppRoutes } from '.././global/routes';
import { sha256 } from "js-sha256";
import * as GlobalModels from '../models/global.models';
import * as RecoModels from '../models/reco.models';


export class Recos {
  http: HttpClient;
  userLocalStorage: any;
  
  constructor(httpInstance: HttpClient) {
    this.http = httpInstance;
    if(localStorage.length !== 0) {
      this.userLocalStorage = JSON.parse(localStorage.getItem(Settings.LC_CURRENT_USER_KEY));
    }
  }

  public GetRecos(status: RecoModels.StatusTypes, page: number, pageSize: number): Observable<GlobalModels.DataTable<RecoModels.Reco[]>> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<GlobalModels.DataTable<RecoModels.Reco[]>>(`${Settings.SERVER_URL}${ServerRoutes.RECOS}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          page,
          pageSize,
          status
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }

  public MarkReco(id: number, isCorrect: boolean): Observable<RecoModels.Reco> {
    if(this.userLocalStorage !== undefined) {
      return this.http.post<RecoModels.Reco>(`${Settings.SERVER_URL}${ServerRoutes.MARK_RECO}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id,
          isCorrect
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    } 
  }

  public CancelReco(id: number): Observable<RecoModels.Reco> { 
    if(this.userLocalStorage !== undefined) {
      return this.http.post<RecoModels.Reco>(`${Settings.SERVER_URL}${ServerRoutes.CANCEL_RECO}`, 
        JSON.stringify({
          token: this.userLocalStorage._authToken,
          phone: this.userLocalStorage.phone,
          id
        }), { headers: Settings.HTTP_CONTENT_TYPE_JSON }
      );
    }
  }
}